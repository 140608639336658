.left-popup {
  z-index: 9999;
  max-width: 500px;
  width: 100%;
  position: absolute;
  top: 0;
  left: -500px;
  background: $add-color;
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.15);
  padding: 20px 45px 35px;
  min-height: 100vh;
  transition: all ease 0.4s;
  &.visible {
    left: 0; }
  &__inner {} }


.popup {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  display: none;
  &__single {
    .row > div > div {
      margin: 0 0 5px; }
    img {
      width: 100%; }
    .single__select {
      justify-content: flex-start;
      .js-select-style {
        margin-left: 10px; } } }
  &__inner {
    max-width: 562px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: url("../imgs/popup-bg.jpg") center no-repeat;
    background-size: cover;
    box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    padding: 20px 45px 35px; }
  &__close {
    position: absolute;
    top: 6px;
    right: 16px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: $primary-color!important;
    text-align: center;
    transform: rotate(45deg);
    font-size: 55px;
    font-weight: 300;
    line-height: 1;
    &:hover,
    &:focus {
      color: #9D9D9D; } }
  &__title {
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 45px; }
  &__btn {
    text-align: center;
    margin: 25px 0 0;
    .btn {
      font-size: 16px; } }
  &__bottom {
    text-align: center;
    margin: 30px 0 0;
    p {
      color: $primary-color;
      a {
        text-decoration: underline; } }
    a {
      display: inline-block;
      color: $primary-color;
      &#registration {
        border-bottom: 1px solid; } } } }


.field-group {
  margin: 0 0 15px;
  &.required {
    position: relative;
    &::before {
      content: '*';
      position: absolute;
      top: 0;
      right: 5px;
      font-size: 24px;
      color: $primary-color; } }
  &__select {
    display: flex;
    .jq-selectbox__select {
      height: 38px;
      line-height: 38px; }
    .jq-selectbox__trigger-arrow {
      top: 17px; } }
  label {
    display: inline-block;
    margin: 0;
    sup {
      color: $primary-color;
      font-size: 20px; } }
  textarea {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 16px;
    color: #bababa;
    background: #fff; }
  input {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 16px;
    color: #bababa;
    background: #fff;
    &::placeholder {
      color: #bababa; } } }
@media screen and (max-width: $desktop) {
  .js-catalog {
    & + ul {
      display: none!important; } }

  #main-left-popup {
    text-align: center;
    background: $add-color;
    .with-list ul {
      padding: 5px 0;
      a {
        text-transform: initial; } }
    .left-popup__inner {
      & > div > a {
        color: #2d2d2d;
        display: block; } }
    .socials {
      a {
        display: inline-block!important;
        padding: 0;
        margin: 10px 5px; } }
    .with-list {
      ul {
        display: none;
        background: $add-color;
        a {
          color: #2d2d2d;
          &::before {
            color: #2d2d2d; } }
        .with-list {
          ul {
            display: none;
            background: $primary-color;
            border-bottom: 1px dashed $add-color;
            a {
              color: #2d2d2d;
              &::before {
                color: $primary-color; } } } } }
      & > a {
        position: relative;
        &.active::before {
          content: '-'; }
        &::before {
          font-size: 24px;
          content: '+';
          color: $primary-color;
          font-weight: 700;
          position: absolute;
          top: -5px;
          right: 20px; } } }
    a {
      display: block;
      font-size: 16px;
      color: #2d2d2d;
      padding: 2px 10px;
      text-transform: uppercase;
      &.mail {
        text-transform: lowercase; }
      &.popup__close {
        top: -4px;
        right: 5px;
        font-size: 55px;
        padding: 0; } }
    .left-popup__title {
      font-size: 18px;
      text-transform: uppercase;
      background: $add-color;
      padding: 8px 10px;
      margin: 0 0 5px; } } }
@media screen and (max-width: 321px) {
  #main-left-popup a {
    font-size: 14px; } }
@media screen and (max-width: $desktop) {
  .popup__inner {
    max-width: 320px;
    border-radius: 0; } }


@media screen and (max-width: $desktop) {
  #catalog-left-popup {
    text-align: center;
    background: $primary-color;
    .with-list {
      ul {
        display: none;
        background: $primary-color;
        a {
          text-transform: capitalize;
          color: #fff;
          &::before {
            color: #fff; } }
        .with-list {
          ul {
            display: none;
            background: $add-color;
            border-bottom: 1px dashed $add-color;
            position: relative;
            &:before {
              content: '';
              position: absolute;
              top: -1px;
              left: 0;
              right: 0;
              height: 1px;
              border-top: 1px dashed $add-color; }
            &:after {
              content: '';
              position: absolute;
              bottom: -2px;
              left: 0;
              right: 0;
              height: 1px;
              border-top: 1px dashed $add-color; }
            a {
              color: #2D2D2D;
              text-transform: lowercase;

              &::before {
                color: #fff; } } } } }
      & > a {
        display: block;
        position: relative;
        & + ul a.active {
          background: $add-color;
          color: #2d2d2d;
          &::before {
            color: #2D2D2D; }
          &::after {
            content: '';
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            height: 1px;
            border-top: 1px dashed $add-color; }
          & + ul {
            position: relative;
            &:after {
              content: '';
              position: absolute;
              bottom: -3px;
              left: 0;
              right: 0;
              height: 1px;
              border-top: 1px dashed $add-color; } } }
        &.active::before {
          content: '-'; }
        &::before {
          font-size: 24px;
          content: '+';
          color: #ffffff;
          font-weight: 700;
          position: absolute;
          top: -7px;
          right: 5px; } } }
    a {
      font-size: 14px;
      color: #fff;
      padding: 2px 10px;
      text-transform: uppercase;
      &.popup__close {
        top: -4px;
        right: 5px;
        font-size: 55px;
        padding: 0; } }
    .left-popup__title {
      font-size: 16px;
      text-transform: uppercase;
      background: $add-color;
      padding: 8px 10px;
      margin: 0 0 5px; } }
  .left-popup {
    position: fixed;
    height: 100vh;
    padding: 0;
    &__inner {
      height: 100vh;
      overflow-x: scroll; } }
  .popup {
    height: 100vh;
    .field-group__select {
      .js-select-style {
        width: 89px; }
      input {
        max-width: calc(100% - 89px); } }
    .field-group input {
      padding: 8px 10px; }
    .row {
      font-size: 14px; }
    .h4 {
      font-size: 14px;
      line-height: 1.2; }
    .single {
      &__price,
      &__selesct,
      &__btns {
        font-size: 16px; } }
    &__title {
      margin: 0 0 20px; }
    &__inner {
      padding: 20px 15px 35px;
      overflow-x: scroll; } }
  .field-group {
    margin: 0 0 7px;
    input {
      padding: 9px 10px; } } }

@media screen and (max-width: $mobile) {
  .popup__inner {
    height: 100vh; }
  #one-click-popup {
    .popup__inner {
      width: 100%;
      max-width: 100%; } } }


#one-click-popup {
  .popup__title {
    margin: 0 0 25px; }
  .field-group {
    margin: 0 0 5px; }
  .field-group label {
    font-size: 14px;
    margin: 0; }
  .popup__bottom {
    margin: 10px 0 0; }
  .popup__btn {
    margin: 15px 0 0; } }

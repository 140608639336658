.header {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  .socials {
    margin: 0 0 17px; }
  &.hidden .header__top {
    transition: all ease 0.4s;
    height: 0;
    padding: 0;
    opacity: 0; }
  &__top {
    height: auto;
    padding: 25px 0 31px;
    transition: all ease 0.4s;
    overflow: hidden;
    .row {
      justify-content: space-between; } }
  &__logo {
    padding: 0 9px; }
  &__info {
    position: relative;
    max-width: 360px;
    padding: 9px 35px 0;
    line-height: 1.2;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background: url("../imgs/line.svg"); }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background: url("../imgs/line.svg"); }
    p {
      font-weight: 300;
      margin: 0 0 17px; }
    b {} }
  &__search {
    padding: 0 25px; }
  &__contacts {
    padding: 0 9px;
    a {
      text-align: right;
      line-height: 1;
      display: block;
      font-size: 26px;
      font-weight: 300;
      margin: 0 0 13px;
      &.mail {
        font-size: 23px;
        margin: 0; } } }
  &__bottom {
    background: $add-color;
    .row {
      & > div,
      & > nav {
        padding: 0 9px; } } }
  &__nav {
    &-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      & > li {
        & > ul {
          display: none; }
        ul {
          position: absolute;
          top: 100%;
          left: 0;
          width: 378px;
          z-index: 101;
          background: $primary-color;
          li {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              top: -1px;
              left: 16px;
              right: 16px;
              height: 2px;
              background: url("../imgs/line-horiz-white.svg"); }
            &.with-list {
              ul {
                top: auto;
                bottom: 0;
                left: 100%;
                width: 0;
                transition: all ease 0.4s;
                opacity: 0;
                &.visible {
                  width: 378px;
                  opacity: 1;
                  transition: all ease 0.4s; } }
              & > a {
                &::after {
                  content: '';
                  position: absolute;
                  top: 16px;
                  right: 5px;
                  border: 8.5px solid transparent;
                  border-left: 6px solid white; }
                &:hover {
                  &::after {
                    border-left-color: #2D2D2D; } } } } }
          a {
            height: auto;
            padding: 19px 21px 16px;
            display: block;
            text-align: left;
            color: #fff;
            &:hover,
            &:focus {
              color: #2D2D2D;
              background: $add-color; } } } } }
    li {
      position: relative; }
    a {
      height: 66px;
      display: inline-block;
      font-size: 14px;
      text-align: center;
      padding: 27px 16px 25px;
      line-height: 1;
      text-transform: uppercase;

      &#catalog,
      &:hover,
      &:focus,
      &.active {
        color: #fff;
        background: $primary-color; } }
    .home {
      a {
        padding: 19px 26px 19px 0;
        &:hover {
          background: rgba(0,0,0,0.0); }
        img {
          height: 28px;
          width: auto; } } } }
  &__cabinet {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto; } }


.logo {
  display: inline-block;
  img {
    padding: 9px 0; } }



.socials {
  a {
    color: #fff;
    background: #fff;
    display: inline-block;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    margin: 0 15px 0 0;
    &:last-child {
      margin-right: 0; } } }

.search {
  position: relative;
  input {
    max-width: 221px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 18px;
    color: #bababa;
    &::placeholder {
      color: #bababa; } }
  button {
    position: absolute;
    top: 2px;
    right: 2px;
    height: 40px;
    width: 40px; } }

.cabinet {
  a {
    &:hover,
    &:focus {
      color: $primary-color; } }
  span {
    padding: 0 3px; } }
.person {
  margin: 0 20px; }
.cart {
  a {
    display: inline-block;
    position: relative;
    padding-right: 13px; }
  img {
    height: 31px;
    width: auto; }
  span {
    position: absolute;
    bottom: -10px;
    right: 0;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    text-align: center;
    min-width: 24px;
    border-radius: 50%;
    background: #fff;
    font-size: 13.5px; } }

.burger,
.tablet-nav,
.mobile-search {
  display: none; }


@media screen and (max-width: $desktop) {
  .search {
    button {
      top: 2px; } }
  .header__nav-list > li ul {
    width: 305px; }
  .header__nav-list > li ul li.with-list ul.visible {
    width: 305px; }
  .logo img {
    max-width: 250px; }

  .tablet-nav {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    background: $add-color; }
  .header {
    &__nav-list {
      & > li {
        display: none;
        &.home,
        &.catalog {
          display: block; } } }
    &__bottom {
      position: relative; }
    &__top {
      padding: 20px 0; }
    &__search {
      padding: 0 15px; }
    &__info {
      padding: 9px 15px 0; }
    &__contacts {
      display: none; } }

  .burger {
    display: block;
    margin: 0 0 0 20px;
    a {
      display: block;
      height: 26px;
      width: 26px;
      position: relative; }
    span {
      display: inline-block;
      position: absolute;
      top: 50%;
      margin-top: -3px;
      left: 0;
      right: 0;
      height: 6px;
      background: $primary-color;
      &::before {
        content: '';
        position: absolute;
        top: -9px;
        left: 0;
        right: 0;
        height: 6px;
        background: inherit; }
      &::after {
        content: '';
        position: absolute;
        bottom: -9px;
        left: 0;
        right: 0;
        height: 6px;
        background: inherit; } } } }

@media screen and (max-width: $tablet) {
  .logo img {
    padding: 0;
    max-width: 230px; }
  //.header__search,
  .header__info {
    display: none; }
  .header__contacts {
    display: block;
    a {
      font-size: 20px;
      & + a {
        margin: 0; } }
    a.mail {
      display: none; } }
  .mobile-search {
    display: block;
    padding: 20px 0 0;
    .search {
      input {
        max-width: 100%; } } } }

@media screen and (max-width: $mobile) {
  .header__search {
    display: none; }
  .mobile-search {
    padding: 20px 0; }
  .header__cabinet {
    .cabinet {
      display: none; } } }

@media screen and (max-width: $small) {
  .header__bottom {
    .row {
      flex-wrap: nowrap; } }
  .search {
    input {
      font-size: 12px;
      padding: 4px; }
    button {
      height: 25px;
      width: 30px; } }
  .mobile-search {
    padding: 8px 0 9px; }
  .cart span {
    height: 20px;
    min-width: 20px;
    line-height: 20px;
    font-size: 10px; }
  .person {
    margin: 0 12px 0 0; }
  .burger {
    margin: 0 0 0 12px; }
  .header {
    &__cabinet {
      img {
        max-height: 25px;
        width: auto; } }
    &__nav {
      .home {
        a {
          padding: 6px 5px 6px 0; } }
      a {
        height: 42px;
        padding: 17px 8px 15px;
        font-size: 10px; } }
    &__top {
      padding: 7px 0 5px; }
    &__logo {
      width: 50%;
      img {
        width: 100%;
        max-width: 130px; } }
    &__contacts {
      width: 50%;
      border-left: 2px dashed $primary-color;
      a {
        font-size: 14px;
        line-height: 1;
        margin: 0 0 2px; } } } }

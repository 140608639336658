.footer {
  position: relative;
  padding-top: 4px;
  &::before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    position: absolute;
    background: url("../imgs/line-horiz.svg"); }
  &__top {
    color: #fff;
    background: $primary-color;
    padding: 45px 0 22px; }
  &__bottom {
    background: $add-color;
    padding: 7px 0;
    p {
      color: #696969;
      font-weight: 300; }
    & .row {
      align-items: center; } }
  &__title {
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 10px; }
  &__nav {
    line-height: 1.2;
    a {
      color: inherit;
      border-bottom: 1px dashed rgba(0,0,0,0.0);
      &:hover,
      &:focus {
        border-bottom: 1px dashed #ffffff; } } }
  &__info {
    line-height: 1.2;
    margin: 0 0 24px;
    a {
      color: inherit;
      &.dashed {
        border-bottom: 1px dashed #ffffff;
        &:hover,
        &:focus {
          border-bottom: 1px dashed rgba(0,0,0,0.0); } } } } }
@media screen and (max-width: $tablet) {
  .footer__title {
    margin: 30px 0 10px; }
  .footer__top {
    padding: 15px 0 22px; } }


@media screen and (max-width: $small) {
  .footer__top {
    padding: 0; }
  .footer__title {
    margin: 30px 0 13px; }

  .footer__bottom {
    padding: 15px 0;
    text-align: center;
    p {
      font-size: 14px;
      line-height: 1.2;
      margin: 0 0 20px; } } }

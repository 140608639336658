.scroll-to-top {
  background: url(../imgs/scroll-to-top.png) no-repeat;
  position: fixed;
  cursor: pointer;
  bottom: 30px;
  right: 30px;
  width: 35px;
  height: 35px;
  display: none;
  z-index: 999; }

body.noscroll {
  overflow: hidden;
  height: 100%; }
.main {
  margin-top: 225px; }
@media screen and (max-width: $small) {
  .main {
    margin-top: 140px; } }
/* typography */
.typography {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
  table {
    width: 100%;
    text-align: center;
    & + p {
      padding: 8px 0 0;
      line-height: 1.2;
      &::before {
        display: none; } }
    td,th {
      position: relative;
      padding: 6px 0 7px;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-image: url("../imgs/table-border.svg"); } } }
  img {
    margin: 20px 0; }
  h1,
  h2,
  h3,
  h4, h5, h6 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.25;
    margin: 44px 0 43px;
    position: relative;

    //&::before
    //content: ''
    //position: absolute
    //bottom: -18px
    //left: 0
    //background: $primary-color
    //height: 9px
    //width: 67%
    //&::after
    //content: ''
    //position: absolute
    //bottom: -31px
    //left: 0
    //background: $add-color
    //height: 7px
 }    //width: 73%

  &--homepage {
    h1,
    h2,
    h3,
    h4, h5, h6 {
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
      line-height: 1.25;
      margin: 44px 0 43px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: -18px;
        left: 0;
        background: $primary-color;
        height: 9px;
        width: 67%; }
      &::after {
        content: '';
        position: absolute;
        bottom: -31px;
        left: 0;
        background: $add-color;
        height: 7px;
        width: 73%; } } }
  p {
    margin: 0 0 25px;
    text-align: justify;
    &:last-child {
      margin: 0; }
    b {
      font-size: 22px;
      font-weight: 700;
      font-style: italic; } }
  ul {
    margin: 27px 0 45px;
    li {
      font-size: 22px;
      padding-left: 20px;
      position: relative;
      line-height: 1.1;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '✓'; } } }
  ol {
    margin: 27px 0 45px;
    counter-reset: num;
    li {
      font-size: 22px;
      padding-left: 20px;
      position: relative;
      line-height: 1.1;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: counter(num) '.';
        counter-increment: num;
        color: $primary-color;
        font-weight: 500; } } } }


@media screen and (max-width: $small) {
  .typography {
    font-size: 16px;
    p {
      margin: 0 0 18px;
      b {
        font-size: 16px; } }
    ul,ol {
      li {
        font-size: 16px; } }
    h1, h2, h3, h4, h5, h6 {
      font-size: 16px;
      margin: 22px 0 19px;
      &::before {
        height: 5px;
        bottom: -9px;
        width: 90%; }
      &::after {
        height: 4px;
        bottom: -16px;
        width: 100%; } } }
  table {
    font-size: 14px;
    line-height: 1.2;
    td:first-child,
    th:first-child {
      text-align: left;
      max-width: 140px; } } }

/* section catalog */
.section-catalog {
  padding: 28px 0 35px; }

.col-catalog {
  padding: 0 9px;
  flex: 0 0 20%;
  max-width: 20%; }

.catalog-item {
  display: block;
  min-height: 242px;
  background-size: cover;
  position: relative;
  margin: 0 0 53px;
  //transition: all ease 0.4s
  &:hover,
  &:focus {
    //box-shadow: inset 0 0 0 7px $primary-color
 }    //transition: all ease 0.4s
  &__title {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    background: url("../imgs/title-bg.png") center no-repeat;
    background-size: cover;
    color: #353535;
    font-size: 14px;
    font-weight: 900;
    padding: 0 20px;
    line-height: 1.2;
    transition: all ease 0.4s; }
  &:hover &__title,
  &:focus &__title {
    bottom: -20px;
    transition: all ease 0.4s; } }


@media screen and (max-width: $desktop) {
  .section-catalog {
    .row {
      justify-content: center; } }
  .col-catalog {
    max-width: 25%;
    flex: 0 0 25%; } }
@media screen and (max-width: $tablet) {
  .col-catalog {
    max-width: 33.3333%;
    flex: 0 0 33.3333%; } }
@media screen and (max-width: $mobile) {
  .section-catalog {
    .container {
      padding-right: 0; }
    .row {
      display: block;
      margin: 0; }
    .col-catalog {
      padding: 0;
      max-width: 100%;
      flex: none; } }
  .catalog-item {
    width: 242px; } }


@media screen and (max-width: $small) {
  .section-catalog {
    padding: 0; }
  .catalog-item__title {
    font-size: 12px;
    padding: 0 10px; } }



/* section about */
.col-about-big {
  padding: 0 9px;
  flex: 0 0 60%;
  max-width: 60%; }
.col-about-small {
  padding: 0 9px;
  flex: 0 0 40%;
  max-width: 40%; }

.section-about {
  background: url("../imgs/about-bg.png") 0 0 no-repeat;
  padding: 39px 0 35px;
  .col-about-big,
  .col-about-small {
    margin: 0 0 59px; }
  ul {
    margin: 63px 0 23px 23px;
    li {
      padding-left: 50px;
      position: relative;
      margin: 0 0 12.5px;
      line-height: 1.2;
      &::before {
        content: '';
        width: 32px;
        height: 27px;
        background: url("../imgs/briliant.svg") center no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0; } } } }

.h2 {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.25;
  margin: 0 0 43px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: -18px;
    background: $primary-color;
    height: 9px;
    width: 112%; }
  &::after {
    content: '';
    position: absolute;
    bottom: -31px;
    background: $add-color;
    height: 7px;
    width: 122%; }
  span {
    display: inline-block;
    max-width: 410px; } }

.after {
  &--left {
    &::after {
      left: 0; }
    &::before {
      left: 0; } }
  &--right {
    &::after {
      right: 0; }
    &::before {
      right: 0; } } }

h4,
.h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.25;
  margin: 0 0 43px; }


@media screen and (max-width: $desktop) {
  .section-about {
    .col-about-small {
      img {
        max-width: 100%; } } } }



@media screen and (max-width: $tablet) {
  .col-about-big,
  .col-about-small {
    padding: 0 9px;
    flex: 0 0 100%;
    max-width: 100%;
    img {
      width: 100%; } }

  .col-about-small--second {
    display: none; }
  .after--right::before,
  .after--right::after {
    right: auto;
    left: 0; } }

@media screen and (max-width: $small) {
  .section-about ul {
    margin: 10px 0;
    li {
      padding-left: 40px;
      margin: 0 0 20px; } }
  .section-about .col-about-big,
  .section-about .col-about-small {
    margin: 0 0 29px; }
  .section-about {
    padding: 10px 0 10px; }
  .h2 {
    font-size: 16px;
    margin: 0 0 24px;
    &::before {
      height: 5px;
      bottom: -9px;
      width: 90%; }
    &::after {
      height: 4px;
      bottom: -16px;
      width: 100%; } }
  .h3 {
    font-size: 18px; } }

/* section buy */
.section-buy {
  background: #FCFCFC;
  .h2 {
    margin: 0 0 5px;
    &::before,
    &::after {
      display: none; }
    span {
      text-align: center; } }
  &__block {
    padding: 50px 0;
    text-align: center; }
  &__subtitle {
    margin: 0 0 10px;
    font-size: 26px;
    font-style: italic; }
  &__text {
    margin: 0 0 15px;
    font-size: 20px;
    font-weight: 300; }
  &__btn {} }

/* section content */
.js-content {
  display: none; }
@media screen and (max-width: $small) {
  .section-buy {
    &__btn {
      position: relative;
      z-index: 2; }
    img {
      width: 430px;
      position: relative;
      margin-top: -65px;
      left: 50%;
      transform: translate(-50%,0); }
    &__subtitle {
      font-size: 22px; }
    &__text {
      font-size: 18px; }
    &__block {
      padding: 45px 0;
      .h2 {
        font-size: 20px; } } } }


.bg-wrapp {
  background: url("../imgs/about-bg2.png") 0 0 no-repeat;
  background-size: 100% 100%; }
.section-content {
  padding: 55px 0 50px;
  background: url("../imgs/about-bg2.png") 0 0 no-repeat;
  background-size: 100% auto;
  &__btn {
    text-align: center;
    a {
      display: inline-block;
      padding: 80px 10px 5px;
      position: relative;
      &::before {
        content: url('../imgs/arr.svg');
        position: absolute;
        top: 45px;
        left: 50%;
        margin-left: -15.5px;
        transform: scale(1,-1);
        transition: all ease 0.4s; }
      &::after {
        content: '';
        background: url("../imgs/line-horiz.svg") center repeat;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px; }
      &:hover,
      &:focus {
        &::before {
          top: 35px;
          transition: all ease 0.4s; } } }
    .active {
      &::before {
        transform: scale(1,1); } } } }

@media screen and (max-width: $small) {
  .section-content {
    padding: 22px 0 20px; } }


/* new section */
.new-section {
  padding: 30px 0 80px;
  .h3 {
    margin: 0 0 48px; } }


@media screen and (max-width: $mobile) {
  .new-section {
    .row {
      margin: 0;
      .col-md-4 {
        padding: 0; } }
    .goods-item {
      width: 249px; } } }
@media screen and (max-width: $small) {
  .new-section {
    padding: 15px 0;
    .h3 {
      margin: 0 0 20px; } } }



/* goods item */
.goods-item {
  display: block;
  text-align: center;
  &__img {
    position: relative;
    //&::before
    ////transition: all ease 0.4s
    ////position: absolute
    ////top: 0
    ////left: 0
    ////right: 0
    ////bottom: 0
    ////content: ''
    ////z-index: 1
    &:hover::before,
    &:focus::before {
      //box-shadow: inset 0 0 0 7px $primary-color
 }      //transition: all ease 0.4s
    img {
      max-width: 100%;
      width: 100%; } }
  &__body {
    padding: 22px 45px 17px;
    line-height: 1.2;
    p {
      font-weight: 300;
      font-size: 18px;
      margin: 0 0 10px; }
    b {
      font-size: 18px; } } }

.badge {
  min-width: 114px;
  padding: 2px 11px;
  background: $primary-color;
  color: #fff;
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 0;
  &--spec {
    background: $add-color;
    color: #2D2D2D; } }


@media screen and (max-width: $desktop) {
  .goods-item {
    &__body {
      padding: 22px 5px 17px; } } }
@media screen and (max-width: $small) {
  .goods-item {
    &__body {
      p {
        font-size: 14px; }
      b {
        font-size: 14px; } } } }

/* esc section */
.escalating-section {
  background: url("../imgs/esc-bg.jpg") center no-repeat;
  background-size: cover;
  padding: 74px 0 64px;
  color: #696969;
  h4 {
    margin: 0 0 3px;
    color: #2d2d2d;
    & + p {
      margin: 0 0 51px; } }
  p {
    text-align: center; }
  &__btn {
    margin: 22px 0 0;
    text-align: center; } }

.col-esc-imgs {
  padding: 0 9px;
  flex: 0 0 47%;
  max-width: 47%;
  display: flex;
  flex-wrap: wrap;
  img {
    margin: 0 5px 5px 0;
    &:nth-child(2n) {
      margin: 0 0 5px; } } }
.col-esc-info {
  padding: 0 9px;
  flex: 0 0 53%;
  max-width: 53%;
  p {
    position: relative;
    padding: 0 0 2px;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-image: url("../imgs/table-border.svg"); } }
  table {
    width: 100%;
    text-align: center;
    & + p {
      padding: 8px 0 0;
      line-height: 1.2;
      &::before {
        display: none; } }
    td,th {
      position: relative;
      padding: 6px 0 7px;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-image: url("../imgs/table-border.svg"); } } } }


@media screen and (max-width: $desktop) {
  .col-esc-imgs img {
    max-width: calc(50% - 5px); } }
@media screen and (max-width: $tablet) {
  .col-esc-imgs {
    padding: 0 9px;
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
    order: 2; }
  .col-esc-info {
    padding: 0 9px;
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
    margin-bottom: 30px; } }

@media screen and (max-width: $small) {
  .col-esc-imgs img {
    max-width: 100%;
    margin: 0 0 5px; }
  .escalating-section__btn {
    margin: 10px 0 0; }
  .col-esc-info table + p {
    font-size: 14px;
    padding: 12px 0 0; }
  .col-esc-info table td,
  .col-esc-info table th {
    padding: 14.5px 0; }
  .col-esc-info p {
    padding: 0 0 22px; }
  .col-esc-info table {
    font-size: 14px;
    line-height: 1.2;
    td:first-child,
    th:first-child {
      text-align: left;
      max-width: 140px; } }
  .escalating-section {
    padding: 20px 0 20px;
    h4 + p {
      font-size: 14px;
      line-height: 1.2;
      margin: 0 0 20px; } }
  h4,h5 {
    font-size: 18px; } }

/* learning section */
.learning-section {
  padding: 112px 0 60px; }

.learning-item {
  background: #FCFCFC;
  padding: 10px;
  &__inner {
    text-align: center;
    padding: 36px 120px 27px;
    //border-style: dashed
    border: 2px dashed $primary-color;
    //border-image-width: 15px
    //border-image-source: url('../imgs/border.svg')
    //border-image-slice: 30
    //border-image-repeat: round
    .h3 {
      margin: 0 0 17px; }
    p {
      color: #2D2D2D;
      font-style: italic;
      font-size: 26px;
      font-weight: 300;
      line-height: 1.0; }
    a {
      margin: 17px 0 0; } } }


@media screen and (max-width: $desktop) {
  .learning-item {
    &__inner {
      padding: 36px 30px 27px; } } }
@media screen and (max-width: $tablet) {
  .learning-item {
    margin: 0 0 15px; } }
@media screen and (max-width: $small) {
  .learning-item p {
    font-size: 22px; }
  .learning-section {
    padding: 20px 0 15px; } }

/* worker section */
.worker-section {
  padding: 55px 0 70px;
  color: #696969;
  .h3 {
    margin: 0 0 7px;
    color: #2d2d2d;
    & + p {
      text-align: center;
      margin: 0 0 43px; } }
  .row > div:nth-child(2) {
    margin-top: 70px; } }


.worker-item {
  text-align: center;
  position: relative;
  &__img {
    display: inline-block;
    width: 282px;
    height: 282px;
    border: 2px dashed $primary-color;
    border-radius: 50%;
    //border: 2px dashed transparent
    //border-style: dashed
    //border-image-width: 48px
    //border-image-source: url('../imgs/border2.svg')
    //border-image-slice: 50
    //border-image-repeat: round
    padding: 7px;
    img {
      width: 100%;
      height: auto;
      border-radius: 50%; } }
  &__title {
    color: #353535;
    font-size: 14px;
    position: absolute;
    left: 50%;
    bottom: 12px;
    text-align: center;
    min-height: 60px;
    width: 275px;
    margin-left: -40px;
    transform: translate(-50%,0);
    background: url("../imgs/title-bg.png") center no-repeat;
    background-size: contain;
    padding: 18px 40px 0 0;
    line-height: 1.2;
    span {
      font-weight: 900;
      text-transform: uppercase; }
    p {} } }

/* insta section */

@media screen and (max-width: $tablet) {
  .worker-section .row > div:nth-child(2) {
    margin: 0; }
  .worker-section {
    .row {
      justify-content: center; } } }
@media screen and (max-width: $small) {
  .worker-section {
    padding: 20px 0 15px;
    .h3 + p {
      margin: 0 0 20px;
      line-height: 1.2;
      font-size: 14px; } }
  .worker-item {
    margin: 0 0 40px;
    &__img {
      border-image-slice: 49;
      border-image-width: 39px;
      height: 232px;
      width: 232px; } }
  .worker-section .row > div:nth-child(2) {
    margin-left: 60px; }
  .worker-section .row > div:nth-child(3) {
    .worker-item {
      margin: 0; } } }


.insta-section {
  padding: 39px 0 50px;
  .h3 {
    margin: 0 0 7px;
    & + p {
      text-align: center;
      margin: 0 0 49px;
      color: #696969; } }
  &__widget {} }


#eapps-instagram-feed-1 .eapps-instagram-feed-posts-grid-load-more {
  display: none; }


@media screen and (max-width: $small) {
  .insta-section {
    padding: 17px 0 15px;
    .h3 + p {
      font-size: 14px;
      line-height: 1.2;
      margin: 0 0 20px; } } }

/* commentssection */
.comments-section {
  padding:  32px 0 50px;
  .h3 {
    margin: 0 0 7px;
    & + p {
      margin: 0 0 78px;
      text-align: center;
      color: #696969; } }
  &__owl {} }

.comment-item {
  text-align: center;
  &__top {
    position: relative;
    margin: 0 0 37px; }
  &__img {
    display: inline-block;
    width: 282px;
    height: 282px;
    border: 2px dashed $primary-color;
    border-radius: 50%;
    //border: 2px dashed transparent
    //border-style: dashed
    //border-image-width: 48px
    //border-image-source: url('../imgs/border2.svg')
    //border-image-slice: 50
    //border-image-repeat: round
    padding: 7px;
    img {
      width: 100%;
      height: auto;
      border-radius: 50%; } }
  &__title {
    color: #353535;
    font-size: 14px;
    position: absolute;
    left: 50%;
    bottom: 12px;
    text-align: center;
    min-height: 60px;
    width: 275px;
    margin-left: -40px;
    transform: translate(-50%,0);
    background: url("../imgs/title-bg.png") center no-repeat;
    background-size: contain;
    padding: 18px 40px 0 0;
    line-height: 1.2;
    span {
      font-weight: 900;
      text-transform: uppercase; } }
  &__text {
    font-weight: 300;
    font-size: 20px;
    color: #696969;
    line-height: 1.2; } }


@media screen and (max-width: $desktop) {
  .comment-item__text {
    font-size: 18px; } }
@media screen and (max-width: $small) {
  .comments-section {
    padding: 25px 0 15px;
    .h3 + p {
      font-size: 14px;
      margin: 0 0 15px; } }
  .comment-item {
    &__top {
      margin: 0 0 27px; }
    &__text {
      font-size: 16px; }
    &__img {
      //border-image-slice: 49
      //border-image-width: 39px
      height: 232px;
      width: 232px; } } }



.owl-theme {
  .owl-dots .owl-dot {
    span {
      height: 20px;
      width: 20px;
      background: #FFFFFF;
      border: 1px solid #979797;
      transition: all ease 0.4s; }
    &:hover,
    &.active {
      span {
        background: #8F8F8F;
        transition: all ease 0.4s; } } }
  .owl-nav.disabled + .owl-dots {
    margin: 60px 0 0; } }

@media screen and (max-width: $small) {
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin: 20px 0 0;
    .owl-dot {
      span {
        height: 13px;
        width: 13px; } } } }

/* breadcrumbs */
.breadcrumbs {
  padding: 41px 0 28px;
  overflow: hidden;
  width: 100%;
  ul {
    display: flex;
    flex-wrap: wrap; }
  li {
    font-size: 18px;
    margin: 0 3px 0 3px; }
  a {
    color: #fff;
    background: $primary-color;
    padding: 6px 10px 6px 15px;
    position: relative;
    line-height: 1;
    &:hover {
      background: $add-color;
      color: #2d2d2d; }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -15px;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 5px solid #fff;
      left: 0;
      transition: all ease 0.4s; }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -15px;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 5px solid $primary-color;
      right: -5px;
      transition: all ease 0.4s; }
    &:hover::after {
      border-left-color: $add-color;
      transition: all ease 0.4s; } }
  span {
    color: #2d2d2d;
    background: $add-color;
    padding: 6px 10px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -15px;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 5px solid #fff;
      left: 0; }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -15px;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 5px solid $add-color;
      right: -5px; } } }


@media screen and (max-width: $mobile) {
  .breadcrumbs {
    display: none; } }

/* single */
.col-single-list {
  padding: 0 9px;
  flex: 0 0 72%;
  max-width: 72%; }
.col-single-info {
  padding: 0 9px;
  flex: 0 0 28%;
  max-width: 28%; }
.single {
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin: 0; }
  &__imgs {
    margin: 0 0 15px;
    &-wrap {
      position: relative;
      .badge {
        z-index: 3; } } }
  &__small-imgs {
    position: relative;
    padding: 0 45px;
    &.owl-theme .owl-nav [class*="owl-"]:hover {
      background: rgba(0,0,0,0.0); }
    &.owl-theme .owl-nav {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      .owl-prev,
      .owl-next {
        position: absolute;
        top: -20px;
        height: 40px;
        width: 40px;
        border-right: 3px solid #BABABA;
        border-bottom: 3px solid #BABABA; }
      .owl-prev {
        left: 0;
        transform: rotate(135deg); }
      .owl-next {
        right: 0;
        transform: rotate(-45deg); } }
    .owl-item {
      border: 1px solid rgba(0,0,0,0.0); }
    .current {
      border: 1px solid #979797; } }
  &__top {
    margin-top: 15px;
    .h3 {
      text-align: left;
      margin: 0 0 20px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4.5px;
    label {
      width: 25%;
      min-width: 25%;
      padding: 0 4.5px 4.5px;
      input {
        display: none;
        &:checked + .color-label {
          transition: all ease 0.4s;
          &::before {
            box-shadow: inset 0 0 0 7px $primary-color;
            transition: all ease 0.4s; } } }
      .color-label {
        position: relative;
        cursor: pointer;
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          transition: all ease 0.4s; }
        &:hover::before {
          box-shadow: inset 0 0 0 7px $primary-color;
          transition: all ease 0.4s; }
        span {
          font-size: 24px;
          position: absolute;
          bottom: 10px;
          left: 0;
          right: 0;
          text-align: center;
          color: #ffffff;
          font-weight: 300; }
        img {} } } }
  &__info {
    font-size: 14px;
    &-inner {
      max-width: 194px;
      & > div {
        margin: 0 0 17px; } }
    b {
      font-weight: 700; }
    .btn-primary {
      font-size: 14px;
      min-width: 103px; }
    .btn-add {
      font-size: 14px;
      min-width: 100%;
      padding: 12px 15px; } }
  &__title {
    font-size: 16px;
    font-weight: 700; }
  &__price {
    font-size: 22px;
    font-weight: 700;
    margin: 0!important; }
  &__select {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  &__btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div,
    & > .btn {
      margin: 0 0 18px; } }
  &__text {
    line-height: 1.2;
    p {
      margin: 0 0 20px; } }
  &__description {
    padding: 49px 0 40px;
    &-title {
      display: flex;
      li {
        padding: 8px 38px;
        font-size: 20px;
        cursor: pointer;
        background: $primary-color;
        color: #ffffff;
        border-radius: 26px 26px 0 0;
        transition: all ease 0.4s;
        &.active,
        &:hover {
          transition: all ease 0.4s;
          background: $add-color;
          color: #2d2d2d; } } }
    &-text {
      line-height: 1.2;
      font-size: 20px;
      font-weight: 300;
      padding: 13px 0;
      li {
        display: none;
        &.active {
          display: block; } }
      p {
        margin: 0 0 25px; } } } }

.single__info--mobile {
  display: none; }
.single__top {
    .h3-mobile {
      display: none; } }
@media screen and (max-width: $desktop) {
  .col-single-list {
    padding: 0 9px;
    flex: 0 0 100%;
    max-width: 100%; }
  .col-single-info {
    padding: 0 9px;
    flex: 0 0 100%;
    max-width: 100%; }

  .single__list label {
    min-width: 16.666667%;
    width: 16.666667%;
    img {
      max-width: 100%; } } }
@media screen and (max-width: $mobile) {
  .single__btns {
    display: flex;
    flex-wrap: wrap;
    .counter {
      width: 90px; }
    .btn-primary {
      flex: 1; } }
  .single__top {
    margin: 0; }
  .single__description-title li {
    font-size: 18px; }
  .single__small-imgs {
    display: none!important; }
  .single__info-inner {
    & > div {
      display: none;
      &.single__btns {
        max-width: 195px;
        margin: 0 auto;
        display: flex; } } }
  .single__info--mobile {
    display: block;
    .single__info-inner {
      & > div {
        display: block; } } }
  .single__top {
    .h3 {
      display: none; }
    .h3-mobile {
      display: block;
      text-align: center; } }
  .single__imgs-wrap {
    max-width: 350px;
    margin: 0 auto; }
  .single__info-inner {
    max-width: 100%;
    text-align: center; }
  .single__select {
    justify-content: center;
    .js-select-style {
      margin-left: 10px; } } }


@media screen and (max-width: $small) {
  .single {
    &__description {
      padding: 0 0 20px;
      &-title {
        flex-wrap: wrap;
        li {
          text-align: center;
          border-radius: 0; }
        li:nth-child(1) {
          width: calc(55% - 2px);
          margin: 0 2px 2px 0; }
        li:nth-child(2) {
          width: 45%;
          margin: 0 0 2px; }
        li:nth-child(3) {
          width: 100%; } } }
    &__imgs {
      margin: 0; }
    &__list {
      label {
        min-width: 25%;
        width: 25%; } } } }


.counter {
  display: flex;
  span {
    line-height: 40px;
    padding: 0 5px;
    cursor: pointer; }
  input {
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    width: 46px;
    height: 40px;
    line-height: 40px;
    padding: 0; } }

.add-goods {
  padding: 36px 0 48px;
  .h3 {
    text-align: center; } }

@media screen and (max-width: $mobile) {
  .add-goods {
    .goods-item {
      width: 249px; } } }
@media screen and (max-width: $small) {
  .add-goods {
    padding: 19px 0 15px; }
  h4, .h3 {
    margin: 0 0 22px; } }


.js-select-style {
  &.jq-selectbox li.selected,
  &.jq-selectbox li:hover {
    background: $primary-color; }
  .jq-selectbox__select {
    border-color: #D8D8D8; }
  &.focused {
    .jq-selectbox__select {
      border-color: #D8D8D8; } }
  .jq-selectbox__select {
    background: #ffffff;
    box-shadow: none; }
  .jq-selectbox__trigger {
    border-left: 0;
    &-arrow {
      border-top-color: #000; } } }

.basket {
  &__title {
    font-size: 20px;
    font-weight: 700; }
  &__delivery-info {
    font-size: 20px;
    padding: 18px 0 50px;
    p {
      margin: 0 0 8px; }
    b {
      font-weight: 700; } }
  &__info-right {
    text-align: right;
    b {
      font-size: 20px;
      font-weight: 700; }
    .btn {
      font-size: 14px;
      margin: 0 0 10px;
      padding: 12px 15px; } }
  &__info-line {
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    padding: 23px 0 7px;
    label {
      display: block;
      cursor: pointer;
      width: 100%;
      font-size: 18px;
      input {
        display: none;
        &:checked + .label::after {
            content: '';
            position: absolute;
            left: 2px;
            top: 4px;
            height: 16px;
            width: 16px;
            background: #D8D8D8;
            border-radius: 50%; } }
      .label {
        position: relative;
        padding-left: 30px;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 2px;
          height: 18px;
          width: 18px;
          border-radius: 50%;
          border: 1px solid #D8D8D8; } } } } }


.basket-item {
  padding: 8px 0;
  display: flex;
  width: 100%;
  border-top: 1px solid #D8D8D8;
  &__img {
    width: 136px;
    display: block;
    img {
      width: 100%;
      height: auto; } }
  &__info {
    flex: 1;
    padding-left: 15px;
    position: relative; }
  &__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase; }
  &__remove {
    position: absolute;
    top: -5px;
    right: 10px;
    color: $primary-color;
    font-size: 50px;
    line-height: 1;
    transform: rotate(45deg); }
  &__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    & > div {
      padding: 35px 5px 0;
      span {
        display: block;
        font-size: 14px;
        margin: 0 0 15px; }
      img {
        width: 74px;
        height: auto; }
      p {
        font-weight: 900;
        font-size: 14px; }
      b {
        font-weight: 700;
        font-size: 22px;
        text-transform: uppercase; } } } }

@media screen and (max-width: $tablet) {
  .basket__delivery-info {
    font-size: 18px; }
  .basket__info-line {
    .row > div:nth-child(1) {
      order: 3; }
    .row > div:nth-child(2) {
      order: 1; }
    .row > div:nth-child(3) {
      order: 2; }
    .row > div:nth-child(4) {
      order: 4; } } }
@media screen and (max-width: $small) {
  .basket__delivery-info {
    padding: 5px 0 10px;
    p {
      margin: 0 0 2px; } }
  .basket__title,
  .basket__delivery-info {
    font-size: 16px; }
  .basket__info-right {
    text-align: center; }
  .basket__info-line {
    padding: 10px 0 5px;
    label {
      font-size: 16px; }
    .row > div:nth-child(1) {
      order: 1;
      margin: 0 0 8px; }
    .row > div:nth-child(2) {
      order: 2; }
    .row > div:nth-child(3) {
      order: 3; }
    .row > div:nth-child(4) {
      order: 4; } }

  .basket-item {
    &__bottom {
      justify-content: flex-start;
      & > div {
        padding: 11px 5px 0;
        img {
          width: 42px; }
        b {
          font-size: 17px; }
        span {
          margin: 0; } } }
    &__remove {
      font-size: 40px;
      top: -10px; }
    &__img {
      width: 80px; }
    &__info {
      padding-left: 5px; }
    &__title {
      font-size: 12px;
      line-height: 1.2;
      padding-right: 30px; } } }


.cabinet-fields {
  padding: 0 0 50px;
  .row {
    justify-content: center; }
  .btn {
    font-size: 14px;
    width: 100%;
    padding: 12px 15px; } }


@media screen and (max-width: $mobile) {
  .cabinet-fields {
    form {
      margin: 0 0 30px; } } }
@media screen and (max-width: $small) {
  .cabinet-fields {
    padding: 0 0 20px; } }

.recom-item {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 18px);
  margin: 0 0 18px;
  align-items: stretch;
  align-content: stretch;
  &:hover &__img::before {
    box-shadow: inset 0 0 0 7px $primary-color;
    transition: all ease 0.4s; }
  &__img {
    position: relative;
    //&::before
    ////position: absolute
    ////top: 0
    ////left: 0
    ////right: 0
    ////bottom: 0
    ////content: ''
    ////box-shadow: inset 0 0 0 0 $primary-color
    ////transition: all ease 0.4s
    img {
      max-width: 100%; } }
  &__body {
    flex: 1;
    position: relative;
    background: $add-color;
    padding: 15px 15px 30px;
    line-height: 1.2; }
  &__title {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 10px; }
  &__text {
    font-weight: 300; }
  &__link {
    position: absolute;
    bottom: 10px;
    right: 15px; } }


.content-page {
  padding: 0 0 50px; }

@media screen and (max-width: $small) {
  .recom-item {
    margin: 0 0 5px;
    &__body {
      padding: 10px 10px 45px; }
    &__title {
      font-size: 14px; } }
  .content-page {
    padding: 0 0 20px; } }


.back-title {
  display: block;
  cursor: auto; }

@media screen and (max-width: $mobile) {
  .back-title {
    cursor: pointer;
    padding: 5px 0 0;
    margin: 0 0 12px;
    span {
      position: relative;
      &::before {
        position: absolute;
        top: 50%;
        margin-top: -13px;
        left: -35px;
        content: '';
        height: 23px;
        width: 23px;
        border-left: 2px solid #D8D8D8;
        border-bottom: 2px solid #D8D8D8;
        transform: rotate(45deg); } } } }

@media screen and (max-width: $small) {
  input,
  option {
    font-size: 16px!important; }
  .back-title {
    span {
      display: block;
      width: 100%;
      position: relative;
      padding: 0 0 0 40px;
      &::before {
        left: 0; } } } }

@charset "UTF-8";
/* COMMON STYLE */
* {
  box-sizing: border-box; }

body {
  background: #fff;
  color: #2d2d2d;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  overflow-x: hidden; }

.wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.main {
  flex: 1 0 auto; }

.footer {
  flex: 0 0 auto; }

a {
  color: #2d2d2d;
  text-decoration: none; }

a:hover,
a:active,
a:focus {
  color: #2d2d2d;
  outline: none; }

img {
  vertical-align: bottom; }

sup,
small {
  font-size: 75%; }

a,
img,
input,
textarea,
button {
  border: 0;
  background: rgba(0, 0, 0, 0);
  transition: all 0.4s ease 0s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input,
textarea,
select,
button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input::placeholder {
  opacity: 1; }

/* H1 - H6 */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Lato", sans-serif; }

/* FORMS */
label {
  display: inline-block;
  margin-bottom: 5px; }

input[type="tel"],
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  border: none;
  display: inline-block;
  font-family: "Lato";
  font-size: 16px;
  padding: 1em 1.5em;
  width: 100%;
  background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
  border-color: #ff9898;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

textarea {
  resize: vertical; }

textarea::placeholder {
  opacity: 1; }

input[type="text"]:focus::placeholder,
input[type="email"]:focus::placeholder,
input[type="password"]:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0; }

/* SELECT */
select {
  /*for WebKit*/
  -webkit-appearance: none;
  /* for FF */
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '...';
  /* for IE */
  -ms-appearance: none;
  appearance: none !important; }

select::-ms-expand {
  display: none; }

/* BUTTONS */
input[type="button"],
input[type="submit"],
input[type="reset"],
button {
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.btn {
  display: inline-block;
  padding: 8px 15px;
  line-height: 1;
  transition: all ease 0.4s;
  background-color: rgba(0, 0, 0, 0);
  color: #000;
  border: 1px solid;
  border-radius: 4px;
  font-size: 22px;
  min-width: 230px;
  max-width: 100%;
  text-align: center; }

@media screen and (max-width: 575px) {
  .btn {
    min-width: 180px;
    width: 100%; } }

.btn-primary {
  background: #ff9898;
  color: #ffffff;
  border-color: #ff9898; }
  .btn-primary:hover, .btn-primary:focus {
    box-shadow: inset 2px 2px 0 #ff9898, inset -2px -2px 0 #ff9898;
    background: rgba(0, 0, 0, 0);
    border-color: #ffe8e8;
    color: #ff9898; }

.btn-add {
  background: #ffe8e8;
  color: #2d2d2d;
  border-color: #ffe8e8; }
  .btn-add:hover, .btn-add:focus {
    box-shadow: inset 2px 2px 0 #ffe8e8, inset -2px -2px 0 #ffe8e8;
    background: rgba(0, 0, 0, 0);
    border-color: #ffe8e8;
    color: #2d2d2d; }

/* CONTAINER */
.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 768px) {
  .container {
    max-width: 750px;
    padding-right: 9px;
    padding-left: 9px; } }

@media (min-width: 992px) {
  .container {
    max-width: 970px; } }

@media (min-width: 1350px) {
  .container {
    max-width: 1300px; } }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -9px;
  margin-left: -9px; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-lg-55, .col-lg-75 {
  position: relative;
  width: 100%;
  padding-right: 9px;
  padding-left: 9px; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-55 {
    -ms-flex: 0 0 38%;
    flex: 0 0 38%;
    max-width: 38%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-75 {
    -ms-flex: 0 0 62%;
    flex: 0 0 62%;
    max-width: 62%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1350px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

.header {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101; }
  .header .socials {
    margin: 0 0 17px; }
  .header.hidden .header__top {
    transition: all ease 0.4s;
    height: 0;
    padding: 0;
    opacity: 0; }
  .header__top {
    height: auto;
    padding: 25px 0 31px;
    transition: all ease 0.4s;
    overflow: hidden; }
    .header__top .row {
      justify-content: space-between; }
  .header__logo {
    padding: 0 9px; }
  .header__info {
    position: relative;
    max-width: 360px;
    padding: 9px 35px 0;
    line-height: 1.2; }
    .header__info:before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background: url("../imgs/line.svg"); }
    .header__info:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background: url("../imgs/line.svg"); }
    .header__info p {
      font-weight: 300;
      margin: 0 0 17px; }
  .header__search {
    padding: 0 25px; }
  .header__contacts {
    padding: 0 9px; }
    .header__contacts a {
      text-align: right;
      line-height: 1;
      display: block;
      font-size: 26px;
      font-weight: 300;
      margin: 0 0 13px; }
      .header__contacts a.mail {
        font-size: 23px;
        margin: 0; }
  .header__bottom {
    background: #ffe8e8; }
    .header__bottom .row > div,
    .header__bottom .row > nav {
      padding: 0 9px; }
  .header__nav-list {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .header__nav-list > li > ul {
      display: none; }
    .header__nav-list > li ul {
      position: absolute;
      top: 100%;
      left: 0;
      width: 378px;
      z-index: 101;
      background: #ff9898; }
      .header__nav-list > li ul li {
        position: relative; }
        .header__nav-list > li ul li::before {
          content: '';
          position: absolute;
          top: -1px;
          left: 16px;
          right: 16px;
          height: 2px;
          background: url("../imgs/line-horiz-white.svg"); }
        .header__nav-list > li ul li.with-list ul {
          top: auto;
          bottom: 0;
          left: 100%;
          width: 0;
          transition: all ease 0.4s;
          opacity: 0; }
          .header__nav-list > li ul li.with-list ul.visible {
            width: 378px;
            opacity: 1;
            transition: all ease 0.4s; }
        .header__nav-list > li ul li.with-list > a::after {
          content: '';
          position: absolute;
          top: 16px;
          right: 5px;
          border: 8.5px solid transparent;
          border-left: 6px solid white; }
        .header__nav-list > li ul li.with-list > a:hover::after {
          border-left-color: #2D2D2D; }
      .header__nav-list > li ul a {
        height: auto;
        padding: 19px 21px 16px;
        display: block;
        text-align: left;
        color: #fff; }
        .header__nav-list > li ul a:hover, .header__nav-list > li ul a:focus {
          color: #2D2D2D;
          background: #ffe8e8; }
  .header__nav li {
    position: relative; }
  .header__nav a {
    height: 66px;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    padding: 27px 16px 25px;
    line-height: 1;
    text-transform: uppercase; }
    .header__nav a#catalog, .header__nav a:hover, .header__nav a:focus, .header__nav a.active {
      color: #fff;
      background: #ff9898; }
  .header__nav .home a {
    padding: 19px 26px 19px 0; }
    .header__nav .home a:hover {
      background: rgba(0, 0, 0, 0); }
    .header__nav .home a img {
      height: 28px;
      width: auto; }
  .header__cabinet {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto; }

.logo {
  display: inline-block; }
  .logo img {
    padding: 9px 0; }

.socials a {
  color: #fff;
  background: #fff;
  display: inline-block;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  margin: 0 15px 0 0; }
  .socials a:last-child {
    margin-right: 0; }

.search {
  position: relative; }
  .search input {
    max-width: 221px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 18px;
    color: #bababa; }
    .search input::placeholder {
      color: #bababa; }
  .search button {
    position: absolute;
    top: 2px;
    right: 2px;
    height: 40px;
    width: 40px; }

.cabinet a:hover, .cabinet a:focus {
  color: #ff9898; }

.cabinet span {
  padding: 0 3px; }

.person {
  margin: 0 20px; }

.cart a {
  display: inline-block;
  position: relative;
  padding-right: 13px; }

.cart img {
  height: 31px;
  width: auto; }

.cart span {
  position: absolute;
  bottom: -10px;
  right: 0;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  text-align: center;
  min-width: 24px;
  border-radius: 50%;
  background: #fff;
  font-size: 13.5px; }

.burger,
.tablet-nav,
.mobile-search {
  display: none; }

@media screen and (max-width: 1349px) {
  .search button {
    top: 2px; }
  .header__nav-list > li ul {
    width: 305px; }
  .header__nav-list > li ul li.with-list ul.visible {
    width: 305px; }
  .logo img {
    max-width: 250px; }
  .tablet-nav {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    background: #ffe8e8; }
  .header__nav-list > li {
    display: none; }
    .header__nav-list > li.home, .header__nav-list > li.catalog {
      display: block; }
  .header__bottom {
    position: relative; }
  .header__top {
    padding: 20px 0; }
  .header__search {
    padding: 0 15px; }
  .header__info {
    padding: 9px 15px 0; }
  .header__contacts {
    display: none; }
  .burger {
    display: block;
    margin: 0 0 0 20px; }
    .burger a {
      display: block;
      height: 26px;
      width: 26px;
      position: relative; }
    .burger span {
      display: inline-block;
      position: absolute;
      top: 50%;
      margin-top: -3px;
      left: 0;
      right: 0;
      height: 6px;
      background: #ff9898; }
      .burger span::before {
        content: '';
        position: absolute;
        top: -9px;
        left: 0;
        right: 0;
        height: 6px;
        background: inherit; }
      .burger span::after {
        content: '';
        position: absolute;
        bottom: -9px;
        left: 0;
        right: 0;
        height: 6px;
        background: inherit; } }

@media screen and (max-width: 991px) {
  .logo img {
    padding: 0;
    max-width: 230px; }
  .header__info {
    display: none; }
  .header__contacts {
    display: block; }
    .header__contacts a {
      font-size: 20px; }
      .header__contacts a + a {
        margin: 0; }
    .header__contacts a.mail {
      display: none; }
  .mobile-search {
    display: block;
    padding: 20px 0 0; }
    .mobile-search .search input {
      max-width: 100%; } }

@media screen and (max-width: 767px) {
  .header__search {
    display: none; }
  .mobile-search {
    padding: 20px 0; }
  .header__cabinet .cabinet {
    display: none; } }

@media screen and (max-width: 575px) {
  .header__bottom .row {
    flex-wrap: nowrap; }
  .search input {
    font-size: 12px;
    padding: 4px; }
  .search button {
    height: 25px;
    width: 30px; }
  .mobile-search {
    padding: 8px 0 9px; }
  .cart span {
    height: 20px;
    min-width: 20px;
    line-height: 20px;
    font-size: 10px; }
  .person {
    margin: 0 12px 0 0; }
  .burger {
    margin: 0 0 0 12px; }
  .header__cabinet img {
    max-height: 25px;
    width: auto; }
  .header__nav .home a {
    padding: 6px 5px 6px 0; }
  .header__nav a {
    height: 42px;
    padding: 17px 8px 15px;
    font-size: 10px; }
  .header__top {
    padding: 7px 0 5px; }
  .header__logo {
    width: 50%; }
    .header__logo img {
      width: 100%;
      max-width: 130px; }
  .header__contacts {
    width: 50%;
    border-left: 2px dashed #ff9898; }
    .header__contacts a {
      font-size: 14px;
      line-height: 1;
      margin: 0 0 2px; } }

.footer {
  position: relative;
  padding-top: 4px; }
  .footer::before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    position: absolute;
    background: url("../imgs/line-horiz.svg"); }
  .footer__top {
    color: #fff;
    background: #ff9898;
    padding: 45px 0 22px; }
  .footer__bottom {
    background: #ffe8e8;
    padding: 7px 0; }
    .footer__bottom p {
      color: #696969;
      font-weight: 300; }
    .footer__bottom .row {
      align-items: center; }
  .footer__title {
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 10px; }
  .footer__nav {
    line-height: 1.2; }
    .footer__nav a {
      color: inherit;
      border-bottom: 1px dashed rgba(0, 0, 0, 0); }
      .footer__nav a:hover, .footer__nav a:focus {
        border-bottom: 1px dashed #ffffff; }
  .footer__info {
    line-height: 1.2;
    margin: 0 0 24px; }
    .footer__info a {
      color: inherit; }
      .footer__info a.dashed {
        border-bottom: 1px dashed #ffffff; }
        .footer__info a.dashed:hover, .footer__info a.dashed:focus {
          border-bottom: 1px dashed rgba(0, 0, 0, 0); }

@media screen and (max-width: 991px) {
  .footer__title {
    margin: 30px 0 10px; }
  .footer__top {
    padding: 15px 0 22px; } }

@media screen and (max-width: 575px) {
  .footer__top {
    padding: 0; }
  .footer__title {
    margin: 30px 0 13px; }
  .footer__bottom {
    padding: 15px 0;
    text-align: center; }
    .footer__bottom p {
      font-size: 14px;
      line-height: 1.2;
      margin: 0 0 20px; } }

.scroll-to-top {
  background: url(../imgs/scroll-to-top.png) no-repeat;
  position: fixed;
  cursor: pointer;
  bottom: 30px;
  right: 30px;
  width: 35px;
  height: 35px;
  display: none;
  z-index: 999; }

body.noscroll {
  overflow: hidden;
  height: 100%; }

.main {
  margin-top: 225px; }

@media screen and (max-width: 575px) {
  .main {
    margin-top: 140px; } }

/* typography */
.typography {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2; }
  .typography table {
    width: 100%;
    text-align: center; }
    .typography table + p {
      padding: 8px 0 0;
      line-height: 1.2; }
      .typography table + p::before {
        display: none; }
    .typography table td, .typography table th {
      position: relative;
      padding: 6px 0 7px; }
      .typography table td::before, .typography table th::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-image: url("../imgs/table-border.svg"); }
  .typography img {
    margin: 20px 0; }
  .typography h1,
  .typography h2,
  .typography h3,
  .typography h4, .typography h5, .typography h6 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.25;
    margin: 44px 0 43px;
    position: relative; }
  .typography--homepage h1,
  .typography--homepage h2,
  .typography--homepage h3,
  .typography--homepage h4, .typography--homepage h5, .typography--homepage h6 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    line-height: 1.25;
    margin: 44px 0 43px;
    position: relative; }
    .typography--homepage h1::before,
    .typography--homepage h2::before,
    .typography--homepage h3::before,
    .typography--homepage h4::before, .typography--homepage h5::before, .typography--homepage h6::before {
      content: '';
      position: absolute;
      bottom: -18px;
      left: 0;
      background: #ff9898;
      height: 9px;
      width: 67%; }
    .typography--homepage h1::after,
    .typography--homepage h2::after,
    .typography--homepage h3::after,
    .typography--homepage h4::after, .typography--homepage h5::after, .typography--homepage h6::after {
      content: '';
      position: absolute;
      bottom: -31px;
      left: 0;
      background: #ffe8e8;
      height: 7px;
      width: 73%; }
  .typography p {
    margin: 0 0 25px;
    text-align: justify; }
    .typography p:last-child {
      margin: 0; }
    .typography p b {
      font-size: 22px;
      font-weight: 700;
      font-style: italic; }
  .typography ul {
    margin: 27px 0 45px; }
    .typography ul li {
      font-size: 22px;
      padding-left: 20px;
      position: relative;
      line-height: 1.1; }
      .typography ul li::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '✓'; }
  .typography ol {
    margin: 27px 0 45px;
    counter-reset: num; }
    .typography ol li {
      font-size: 22px;
      padding-left: 20px;
      position: relative;
      line-height: 1.1; }
      .typography ol li::before {
        position: absolute;
        top: 0;
        left: 0;
        content: counter(num) ".";
        counter-increment: num;
        color: #ff9898;
        font-weight: 500; }

@media screen and (max-width: 575px) {
  .typography {
    font-size: 16px; }
    .typography p {
      margin: 0 0 18px; }
      .typography p b {
        font-size: 16px; }
    .typography ul li, .typography ol li {
      font-size: 16px; }
    .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
      font-size: 16px;
      margin: 22px 0 19px; }
      .typography h1::before, .typography h2::before, .typography h3::before, .typography h4::before, .typography h5::before, .typography h6::before {
        height: 5px;
        bottom: -9px;
        width: 90%; }
      .typography h1::after, .typography h2::after, .typography h3::after, .typography h4::after, .typography h5::after, .typography h6::after {
        height: 4px;
        bottom: -16px;
        width: 100%; }
  table {
    font-size: 14px;
    line-height: 1.2; }
    table td:first-child,
    table th:first-child {
      text-align: left;
      max-width: 140px; } }

/* section catalog */
.section-catalog {
  padding: 28px 0 35px; }

.col-catalog {
  padding: 0 9px;
  flex: 0 0 20%;
  max-width: 20%; }

.catalog-item {
  display: block;
  min-height: 242px;
  background-size: cover;
  position: relative;
  margin: 0 0 53px; }
  .catalog-item__title {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    background: url("../imgs/title-bg.png") center no-repeat;
    background-size: cover;
    color: #353535;
    font-size: 14px;
    font-weight: 900;
    padding: 0 20px;
    line-height: 1.2;
    transition: all ease 0.4s; }
  .catalog-item:hover .catalog-item__title,
  .catalog-item:focus .catalog-item__title {
    bottom: -20px;
    transition: all ease 0.4s; }

@media screen and (max-width: 1349px) {
  .section-catalog .row {
    justify-content: center; }
  .col-catalog {
    max-width: 25%;
    flex: 0 0 25%; } }

@media screen and (max-width: 991px) {
  .col-catalog {
    max-width: 33.3333%;
    flex: 0 0 33.3333%; } }

@media screen and (max-width: 767px) {
  .section-catalog .container {
    padding-right: 0; }
  .section-catalog .row {
    display: block;
    margin: 0; }
  .section-catalog .col-catalog {
    padding: 0;
    max-width: 100%;
    flex: none; }
  .catalog-item {
    width: 242px; } }

@media screen and (max-width: 575px) {
  .section-catalog {
    padding: 0; }
  .catalog-item__title {
    font-size: 12px;
    padding: 0 10px; } }

/* section about */
.col-about-big {
  padding: 0 9px;
  flex: 0 0 60%;
  max-width: 60%; }

.col-about-small {
  padding: 0 9px;
  flex: 0 0 40%;
  max-width: 40%; }

.section-about {
  background: url("../imgs/about-bg.png") 0 0 no-repeat;
  padding: 39px 0 35px; }
  .section-about .col-about-big,
  .section-about .col-about-small {
    margin: 0 0 59px; }
  .section-about ul {
    margin: 63px 0 23px 23px; }
    .section-about ul li {
      padding-left: 50px;
      position: relative;
      margin: 0 0 12.5px;
      line-height: 1.2; }
      .section-about ul li::before {
        content: '';
        width: 32px;
        height: 27px;
        background: url("../imgs/briliant.svg") center no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0; }

.h2 {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.25;
  margin: 0 0 43px;
  position: relative; }
  .h2::before {
    content: '';
    position: absolute;
    bottom: -18px;
    background: #ff9898;
    height: 9px;
    width: 112%; }
  .h2::after {
    content: '';
    position: absolute;
    bottom: -31px;
    background: #ffe8e8;
    height: 7px;
    width: 122%; }
  .h2 span {
    display: inline-block;
    max-width: 410px; }

.after--left::after {
  left: 0; }

.after--left::before {
  left: 0; }

.after--right::after {
  right: 0; }

.after--right::before {
  right: 0; }

h4,
.h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.25;
  margin: 0 0 43px; }

@media screen and (max-width: 1349px) {
  .section-about .col-about-small img {
    max-width: 100%; } }

@media screen and (max-width: 991px) {
  .col-about-big,
  .col-about-small {
    padding: 0 9px;
    flex: 0 0 100%;
    max-width: 100%; }
    .col-about-big img,
    .col-about-small img {
      width: 100%; }
  .col-about-small--second {
    display: none; }
  .after--right::before,
  .after--right::after {
    right: auto;
    left: 0; } }

@media screen and (max-width: 575px) {
  .section-about ul {
    margin: 10px 0; }
    .section-about ul li {
      padding-left: 40px;
      margin: 0 0 20px; }
  .section-about .col-about-big,
  .section-about .col-about-small {
    margin: 0 0 29px; }
  .section-about {
    padding: 10px 0 10px; }
  .h2 {
    font-size: 16px;
    margin: 0 0 24px; }
    .h2::before {
      height: 5px;
      bottom: -9px;
      width: 90%; }
    .h2::after {
      height: 4px;
      bottom: -16px;
      width: 100%; }
  .h3 {
    font-size: 18px; } }

/* section buy */
.section-buy {
  background: #FCFCFC; }
  .section-buy .h2 {
    margin: 0 0 5px; }
    .section-buy .h2::before, .section-buy .h2::after {
      display: none; }
    .section-buy .h2 span {
      text-align: center; }
  .section-buy__block {
    padding: 50px 0;
    text-align: center; }
  .section-buy__subtitle {
    margin: 0 0 10px;
    font-size: 26px;
    font-style: italic; }
  .section-buy__text {
    margin: 0 0 15px;
    font-size: 20px;
    font-weight: 300; }

/* section content */
.js-content {
  display: none; }

@media screen and (max-width: 575px) {
  .section-buy__btn {
    position: relative;
    z-index: 2; }
  .section-buy img {
    width: 430px;
    position: relative;
    margin-top: -65px;
    left: 50%;
    transform: translate(-50%, 0); }
  .section-buy__subtitle {
    font-size: 22px; }
  .section-buy__text {
    font-size: 18px; }
  .section-buy__block {
    padding: 45px 0; }
    .section-buy__block .h2 {
      font-size: 20px; } }

.bg-wrapp {
  background: url("../imgs/about-bg2.png") 0 0 no-repeat;
  background-size: 100% 100%; }

.section-content {
  padding: 55px 0 50px;
  background: url("../imgs/about-bg2.png") 0 0 no-repeat;
  background-size: 100% auto; }
  .section-content__btn {
    text-align: center; }
    .section-content__btn a {
      display: inline-block;
      padding: 80px 10px 5px;
      position: relative; }
      .section-content__btn a::before {
        content: url("../imgs/arr.svg");
        position: absolute;
        top: 45px;
        left: 50%;
        margin-left: -15.5px;
        transform: scale(1, -1);
        transition: all ease 0.4s; }
      .section-content__btn a::after {
        content: '';
        background: url("../imgs/line-horiz.svg") center repeat;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px; }
      .section-content__btn a:hover::before, .section-content__btn a:focus::before {
        top: 35px;
        transition: all ease 0.4s; }
    .section-content__btn .active::before {
      transform: scale(1, 1); }

@media screen and (max-width: 575px) {
  .section-content {
    padding: 22px 0 20px; } }

/* new section */
.new-section {
  padding: 30px 0 80px; }
  .new-section .h3 {
    margin: 0 0 48px; }

@media screen and (max-width: 767px) {
  .new-section .row {
    margin: 0; }
    .new-section .row .col-md-4 {
      padding: 0; }
  .new-section .goods-item {
    width: 249px; } }

@media screen and (max-width: 575px) {
  .new-section {
    padding: 15px 0; }
    .new-section .h3 {
      margin: 0 0 20px; } }

/* goods item */
.goods-item {
  display: block;
  text-align: center; }
  .goods-item__img {
    position: relative; }
    .goods-item__img img {
      max-width: 100%;
      width: 100%; }
  .goods-item__body {
    padding: 22px 45px 17px;
    line-height: 1.2; }
    .goods-item__body p {
      font-weight: 300;
      font-size: 18px;
      margin: 0 0 10px; }
    .goods-item__body b {
      font-size: 18px; }

.badge {
  min-width: 114px;
  padding: 2px 11px;
  background: #ff9898;
  color: #fff;
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 0; }
  .badge--spec {
    background: #ffe8e8;
    color: #2D2D2D; }

@media screen and (max-width: 1349px) {
  .goods-item__body {
    padding: 22px 5px 17px; } }

@media screen and (max-width: 575px) {
  .goods-item__body p {
    font-size: 14px; }
  .goods-item__body b {
    font-size: 14px; } }

/* esc section */
.escalating-section {
  background: url("../imgs/esc-bg.jpg") center no-repeat;
  background-size: cover;
  padding: 74px 0 64px;
  color: #696969; }
  .escalating-section h4 {
    margin: 0 0 3px;
    color: #2d2d2d; }
    .escalating-section h4 + p {
      margin: 0 0 51px; }
  .escalating-section p {
    text-align: center; }
  .escalating-section__btn {
    margin: 22px 0 0;
    text-align: center; }

.col-esc-imgs {
  padding: 0 9px;
  flex: 0 0 47%;
  max-width: 47%;
  display: flex;
  flex-wrap: wrap; }
  .col-esc-imgs img {
    margin: 0 5px 5px 0; }
    .col-esc-imgs img:nth-child(2n) {
      margin: 0 0 5px; }

.col-esc-info {
  padding: 0 9px;
  flex: 0 0 53%;
  max-width: 53%; }
  .col-esc-info p {
    position: relative;
    padding: 0 0 2px; }
    .col-esc-info p::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-image: url("../imgs/table-border.svg"); }
  .col-esc-info table {
    width: 100%;
    text-align: center; }
    .col-esc-info table + p {
      padding: 8px 0 0;
      line-height: 1.2; }
      .col-esc-info table + p::before {
        display: none; }
    .col-esc-info table td, .col-esc-info table th {
      position: relative;
      padding: 6px 0 7px; }
      .col-esc-info table td::before, .col-esc-info table th::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-image: url("../imgs/table-border.svg"); }

@media screen and (max-width: 1349px) {
  .col-esc-imgs img {
    max-width: calc(50% - 5px); } }

@media screen and (max-width: 991px) {
  .col-esc-imgs {
    padding: 0 9px;
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
    order: 2; }
  .col-esc-info {
    padding: 0 9px;
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
    margin-bottom: 30px; } }

@media screen and (max-width: 575px) {
  .col-esc-imgs img {
    max-width: 100%;
    margin: 0 0 5px; }
  .escalating-section__btn {
    margin: 10px 0 0; }
  .col-esc-info table + p {
    font-size: 14px;
    padding: 12px 0 0; }
  .col-esc-info table td,
  .col-esc-info table th {
    padding: 14.5px 0; }
  .col-esc-info p {
    padding: 0 0 22px; }
  .col-esc-info table {
    font-size: 14px;
    line-height: 1.2; }
    .col-esc-info table td:first-child,
    .col-esc-info table th:first-child {
      text-align: left;
      max-width: 140px; }
  .escalating-section {
    padding: 20px 0 20px; }
    .escalating-section h4 + p {
      font-size: 14px;
      line-height: 1.2;
      margin: 0 0 20px; }
  h4, h5 {
    font-size: 18px; } }

/* learning section */
.learning-section {
  padding: 112px 0 60px; }

.learning-item {
  background: #FCFCFC;
  padding: 10px; }
  .learning-item__inner {
    text-align: center;
    padding: 36px 120px 27px;
    border: 2px dashed #ff9898; }
    .learning-item__inner .h3 {
      margin: 0 0 17px; }
    .learning-item__inner p {
      color: #2D2D2D;
      font-style: italic;
      font-size: 26px;
      font-weight: 300;
      line-height: 1.0; }
    .learning-item__inner a {
      margin: 17px 0 0; }

@media screen and (max-width: 1349px) {
  .learning-item__inner {
    padding: 36px 30px 27px; } }

@media screen and (max-width: 991px) {
  .learning-item {
    margin: 0 0 15px; } }

@media screen and (max-width: 575px) {
  .learning-item p {
    font-size: 22px; }
  .learning-section {
    padding: 20px 0 15px; } }

/* worker section */
.worker-section {
  padding: 55px 0 70px;
  color: #696969; }
  .worker-section .h3 {
    margin: 0 0 7px;
    color: #2d2d2d; }
    .worker-section .h3 + p {
      text-align: center;
      margin: 0 0 43px; }
  .worker-section .row > div:nth-child(2) {
    margin-top: 70px; }

.worker-item {
  text-align: center;
  position: relative; }
  .worker-item__img {
    display: inline-block;
    width: 282px;
    height: 282px;
    border: 2px dashed #ff9898;
    border-radius: 50%;
    padding: 7px; }
    .worker-item__img img {
      width: 100%;
      height: auto;
      border-radius: 50%; }
  .worker-item__title {
    color: #353535;
    font-size: 14px;
    position: absolute;
    left: 50%;
    bottom: 12px;
    text-align: center;
    min-height: 60px;
    width: 275px;
    margin-left: -40px;
    transform: translate(-50%, 0);
    background: url("../imgs/title-bg.png") center no-repeat;
    background-size: contain;
    padding: 18px 40px 0 0;
    line-height: 1.2; }
    .worker-item__title span {
      font-weight: 900;
      text-transform: uppercase; }

/* insta section */
@media screen and (max-width: 991px) {
  .worker-section .row > div:nth-child(2) {
    margin: 0; }
  .worker-section .row {
    justify-content: center; } }

@media screen and (max-width: 575px) {
  .worker-section {
    padding: 20px 0 15px; }
    .worker-section .h3 + p {
      margin: 0 0 20px;
      line-height: 1.2;
      font-size: 14px; }
  .worker-item {
    margin: 0 0 40px; }
    .worker-item__img {
      border-image-slice: 49;
      border-image-width: 39px;
      height: 232px;
      width: 232px; }
  .worker-section .row > div:nth-child(2) {
    margin-left: 60px; }
  .worker-section .row > div:nth-child(3) .worker-item {
    margin: 0; } }

.insta-section {
  padding: 39px 0 50px; }
  .insta-section .h3 {
    margin: 0 0 7px; }
    .insta-section .h3 + p {
      text-align: center;
      margin: 0 0 49px;
      color: #696969; }

#eapps-instagram-feed-1 .eapps-instagram-feed-posts-grid-load-more {
  display: none; }

@media screen and (max-width: 575px) {
  .insta-section {
    padding: 17px 0 15px; }
    .insta-section .h3 + p {
      font-size: 14px;
      line-height: 1.2;
      margin: 0 0 20px; } }

/* commentssection */
.comments-section {
  padding: 32px 0 50px; }
  .comments-section .h3 {
    margin: 0 0 7px; }
    .comments-section .h3 + p {
      margin: 0 0 78px;
      text-align: center;
      color: #696969; }

.comment-item {
  text-align: center; }
  .comment-item__top {
    position: relative;
    margin: 0 0 37px; }
  .comment-item__img {
    display: inline-block;
    width: 282px;
    height: 282px;
    border: 2px dashed #ff9898;
    border-radius: 50%;
    padding: 7px; }
    .comment-item__img img {
      width: 100%;
      height: auto;
      border-radius: 50%; }
  .comment-item__title {
    color: #353535;
    font-size: 14px;
    position: absolute;
    left: 50%;
    bottom: 12px;
    text-align: center;
    min-height: 60px;
    width: 275px;
    margin-left: -40px;
    transform: translate(-50%, 0);
    background: url("../imgs/title-bg.png") center no-repeat;
    background-size: contain;
    padding: 18px 40px 0 0;
    line-height: 1.2; }
    .comment-item__title span {
      font-weight: 900;
      text-transform: uppercase; }
  .comment-item__text {
    font-weight: 300;
    font-size: 20px;
    color: #696969;
    line-height: 1.2; }

@media screen and (max-width: 1349px) {
  .comment-item__text {
    font-size: 18px; } }

@media screen and (max-width: 575px) {
  .comments-section {
    padding: 25px 0 15px; }
    .comments-section .h3 + p {
      font-size: 14px;
      margin: 0 0 15px; }
  .comment-item__top {
    margin: 0 0 27px; }
  .comment-item__text {
    font-size: 16px; }
  .comment-item__img {
    height: 232px;
    width: 232px; } }

.owl-theme .owl-dots .owl-dot span {
  height: 20px;
  width: 20px;
  background: #FFFFFF;
  border: 1px solid #979797;
  transition: all ease 0.4s; }

.owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot.active span {
  background: #8F8F8F;
  transition: all ease 0.4s; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin: 60px 0 0; }

@media screen and (max-width: 575px) {
  .owl-theme .owl-nav.disabled + .owl-dots {
    margin: 20px 0 0; }
    .owl-theme .owl-nav.disabled + .owl-dots .owl-dot span {
      height: 13px;
      width: 13px; } }

/* breadcrumbs */
.breadcrumbs {
  padding: 41px 0 28px;
  overflow: hidden;
  width: 100%; }
  .breadcrumbs ul {
    display: flex;
    flex-wrap: wrap; }
  .breadcrumbs li {
    font-size: 18px;
    margin: 0 3px 0 3px; }
  .breadcrumbs a {
    color: #fff;
    background: #ff9898;
    padding: 6px 10px 6px 15px;
    position: relative;
    line-height: 1; }
    .breadcrumbs a:hover {
      background: #ffe8e8;
      color: #2d2d2d; }
    .breadcrumbs a::before {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -15px;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 5px solid #fff;
      left: 0;
      transition: all ease 0.4s; }
    .breadcrumbs a::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -15px;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 5px solid #ff9898;
      right: -5px;
      transition: all ease 0.4s; }
    .breadcrumbs a:hover::after {
      border-left-color: #ffe8e8;
      transition: all ease 0.4s; }
  .breadcrumbs span {
    color: #2d2d2d;
    background: #ffe8e8;
    padding: 6px 10px;
    position: relative; }
    .breadcrumbs span::before {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -15px;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 5px solid #fff;
      left: 0; }
    .breadcrumbs span::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -15px;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 5px solid #ffe8e8;
      right: -5px; }

@media screen and (max-width: 767px) {
  .breadcrumbs {
    display: none; } }

/* single */
.col-single-list {
  padding: 0 9px;
  flex: 0 0 72%;
  max-width: 72%; }

.col-single-info {
  padding: 0 9px;
  flex: 0 0 28%;
  max-width: 28%; }

.single .owl-theme .owl-nav.disabled + .owl-dots {
  margin: 0; }

.single__imgs {
  margin: 0 0 15px; }
  .single__imgs-wrap {
    position: relative; }
    .single__imgs-wrap .badge {
      z-index: 3; }

.single__small-imgs {
  position: relative;
  padding: 0 45px; }
  .single__small-imgs.owl-theme .owl-nav [class*="owl-"]:hover {
    background: rgba(0, 0, 0, 0); }
  .single__small-imgs.owl-theme .owl-nav {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0; }
    .single__small-imgs.owl-theme .owl-nav .owl-prev,
    .single__small-imgs.owl-theme .owl-nav .owl-next {
      position: absolute;
      top: -20px;
      height: 40px;
      width: 40px;
      border-right: 3px solid #BABABA;
      border-bottom: 3px solid #BABABA; }
    .single__small-imgs.owl-theme .owl-nav .owl-prev {
      left: 0;
      transform: rotate(135deg); }
    .single__small-imgs.owl-theme .owl-nav .owl-next {
      right: 0;
      transform: rotate(-45deg); }
  .single__small-imgs .owl-item {
    border: 1px solid rgba(0, 0, 0, 0); }
  .single__small-imgs .current {
    border: 1px solid #979797; }

.single__top {
  margin-top: 15px; }
  .single__top .h3 {
    text-align: left;
    margin: 0 0 20px; }

.single__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4.5px; }
  .single__list label {
    width: 25%;
    min-width: 25%;
    padding: 0 4.5px 4.5px; }
    .single__list label input {
      display: none; }
      .single__list label input:checked + .color-label {
        transition: all ease 0.4s; }
        .single__list label input:checked + .color-label::before {
          box-shadow: inset 0 0 0 7px #ff9898;
          transition: all ease 0.4s; }
    .single__list label .color-label {
      position: relative;
      cursor: pointer; }
      .single__list label .color-label::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        transition: all ease 0.4s; }
      .single__list label .color-label:hover::before {
        box-shadow: inset 0 0 0 7px #ff9898;
        transition: all ease 0.4s; }
      .single__list label .color-label span {
        font-size: 24px;
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        text-align: center;
        color: #ffffff;
        font-weight: 300; }

.single__info {
  font-size: 14px; }
  .single__info-inner {
    max-width: 194px; }
    .single__info-inner > div {
      margin: 0 0 17px; }
  .single__info b {
    font-weight: 700; }
  .single__info .btn-primary {
    font-size: 14px;
    min-width: 103px; }
  .single__info .btn-add {
    font-size: 14px;
    min-width: 100%;
    padding: 12px 15px; }

.single__title {
  font-size: 16px;
  font-weight: 700; }

.single__price {
  font-size: 22px;
  font-weight: 700;
  margin: 0 !important; }

.single__select {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.single__btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .single__btns > div,
  .single__btns > .btn {
    margin: 0 0 18px; }

.single__text {
  line-height: 1.2; }
  .single__text p {
    margin: 0 0 20px; }

.single__description {
  padding: 49px 0 40px; }
  .single__description-title {
    display: flex; }
    .single__description-title li {
      padding: 8px 38px;
      font-size: 20px;
      cursor: pointer;
      background: #ff9898;
      color: #ffffff;
      border-radius: 26px 26px 0 0;
      transition: all ease 0.4s; }
      .single__description-title li.active, .single__description-title li:hover {
        transition: all ease 0.4s;
        background: #ffe8e8;
        color: #2d2d2d; }
  .single__description-text {
    line-height: 1.2;
    font-size: 20px;
    font-weight: 300;
    padding: 13px 0; }
    .single__description-text li {
      display: none; }
      .single__description-text li.active {
        display: block; }
    .single__description-text p {
      margin: 0 0 25px; }

.single__info--mobile {
  display: none; }

.single__top .h3-mobile {
  display: none; }

@media screen and (max-width: 1349px) {
  .col-single-list {
    padding: 0 9px;
    flex: 0 0 100%;
    max-width: 100%; }
  .col-single-info {
    padding: 0 9px;
    flex: 0 0 100%;
    max-width: 100%; }
  .single__list label {
    min-width: 16.666667%;
    width: 16.666667%; }
    .single__list label img {
      max-width: 100%; } }

@media screen and (max-width: 767px) {
  .single__btns {
    display: flex;
    flex-wrap: wrap; }
    .single__btns .counter {
      width: 90px; }
    .single__btns .btn-primary {
      flex: 1; }
  .single__top {
    margin: 0; }
  .single__description-title li {
    font-size: 18px; }
  .single__small-imgs {
    display: none !important; }
  .single__info-inner > div {
    display: none; }
    .single__info-inner > div.single__btns {
      max-width: 195px;
      margin: 0 auto;
      display: flex; }
  .single__info--mobile {
    display: block; }
    .single__info--mobile .single__info-inner > div {
      display: block; }
  .single__top .h3 {
    display: none; }
  .single__top .h3-mobile {
    display: block;
    text-align: center; }
  .single__imgs-wrap {
    max-width: 350px;
    margin: 0 auto; }
  .single__info-inner {
    max-width: 100%;
    text-align: center; }
  .single__select {
    justify-content: center; }
    .single__select .js-select-style {
      margin-left: 10px; } }

@media screen and (max-width: 575px) {
  .single__description {
    padding: 0 0 20px; }
    .single__description-title {
      flex-wrap: wrap; }
      .single__description-title li {
        text-align: center;
        border-radius: 0; }
      .single__description-title li:nth-child(1) {
        width: calc(55% - 2px);
        margin: 0 2px 2px 0; }
      .single__description-title li:nth-child(2) {
        width: 45%;
        margin: 0 0 2px; }
      .single__description-title li:nth-child(3) {
        width: 100%; }
  .single__imgs {
    margin: 0; }
  .single__list label {
    min-width: 25%;
    width: 25%; } }

.counter {
  display: flex; }
  .counter span {
    line-height: 40px;
    padding: 0 5px;
    cursor: pointer; }
  .counter input {
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    width: 46px;
    height: 40px;
    line-height: 40px;
    padding: 0; }

.add-goods {
  padding: 36px 0 48px; }
  .add-goods .h3 {
    text-align: center; }

@media screen and (max-width: 767px) {
  .add-goods .goods-item {
    width: 249px; } }

@media screen and (max-width: 575px) {
  .add-goods {
    padding: 19px 0 15px; }
  h4, .h3 {
    margin: 0 0 22px; } }

.js-select-style.jq-selectbox li.selected,
.js-select-style.jq-selectbox li:hover {
  background: #ff9898; }

.js-select-style .jq-selectbox__select {
  border-color: #D8D8D8; }

.js-select-style.focused .jq-selectbox__select {
  border-color: #D8D8D8; }

.js-select-style .jq-selectbox__select {
  background: #ffffff;
  box-shadow: none; }

.js-select-style .jq-selectbox__trigger {
  border-left: 0; }
  .js-select-style .jq-selectbox__trigger-arrow {
    border-top-color: #000; }

.basket__title {
  font-size: 20px;
  font-weight: 700; }

.basket__delivery-info {
  font-size: 20px;
  padding: 18px 0 50px; }
  .basket__delivery-info p {
    margin: 0 0 8px; }
  .basket__delivery-info b {
    font-weight: 700; }

.basket__info-right {
  text-align: right; }
  .basket__info-right b {
    font-size: 20px;
    font-weight: 700; }
  .basket__info-right .btn {
    font-size: 14px;
    margin: 0 0 10px;
    padding: 12px 15px; }

.basket__info-line {
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  padding: 23px 0 7px; }
  .basket__info-line label {
    display: block;
    cursor: pointer;
    width: 100%;
    font-size: 18px; }
    .basket__info-line label input {
      display: none; }
      .basket__info-line label input:checked + .label::after {
        content: '';
        position: absolute;
        left: 2px;
        top: 4px;
        height: 16px;
        width: 16px;
        background: #D8D8D8;
        border-radius: 50%; }
    .basket__info-line label .label {
      position: relative;
      padding-left: 30px; }
      .basket__info-line label .label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        border: 1px solid #D8D8D8; }

.basket-item {
  padding: 8px 0;
  display: flex;
  width: 100%;
  border-top: 1px solid #D8D8D8; }
  .basket-item__img {
    width: 136px;
    display: block; }
    .basket-item__img img {
      width: 100%;
      height: auto; }
  .basket-item__info {
    flex: 1;
    padding-left: 15px;
    position: relative; }
  .basket-item__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase; }
  .basket-item__remove {
    position: absolute;
    top: -5px;
    right: 10px;
    color: #ff9898;
    font-size: 50px;
    line-height: 1;
    transform: rotate(45deg); }
  .basket-item__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
    .basket-item__bottom > div {
      padding: 35px 5px 0; }
      .basket-item__bottom > div span {
        display: block;
        font-size: 14px;
        margin: 0 0 15px; }
      .basket-item__bottom > div img {
        width: 74px;
        height: auto; }
      .basket-item__bottom > div p {
        font-weight: 900;
        font-size: 14px; }
      .basket-item__bottom > div b {
        font-weight: 700;
        font-size: 22px;
        text-transform: uppercase; }

@media screen and (max-width: 991px) {
  .basket__delivery-info {
    font-size: 18px; }
  .basket__info-line .row > div:nth-child(1) {
    order: 3; }
  .basket__info-line .row > div:nth-child(2) {
    order: 1; }
  .basket__info-line .row > div:nth-child(3) {
    order: 2; }
  .basket__info-line .row > div:nth-child(4) {
    order: 4; } }

@media screen and (max-width: 575px) {
  .basket__delivery-info {
    padding: 5px 0 10px; }
    .basket__delivery-info p {
      margin: 0 0 2px; }
  .basket__title,
  .basket__delivery-info {
    font-size: 16px; }
  .basket__info-right {
    text-align: center; }
  .basket__info-line {
    padding: 10px 0 5px; }
    .basket__info-line label {
      font-size: 16px; }
    .basket__info-line .row > div:nth-child(1) {
      order: 1;
      margin: 0 0 8px; }
    .basket__info-line .row > div:nth-child(2) {
      order: 2; }
    .basket__info-line .row > div:nth-child(3) {
      order: 3; }
    .basket__info-line .row > div:nth-child(4) {
      order: 4; }
  .basket-item__bottom {
    justify-content: flex-start; }
    .basket-item__bottom > div {
      padding: 11px 5px 0; }
      .basket-item__bottom > div img {
        width: 42px; }
      .basket-item__bottom > div b {
        font-size: 17px; }
      .basket-item__bottom > div span {
        margin: 0; }
  .basket-item__remove {
    font-size: 40px;
    top: -10px; }
  .basket-item__img {
    width: 80px; }
  .basket-item__info {
    padding-left: 5px; }
  .basket-item__title {
    font-size: 12px;
    line-height: 1.2;
    padding-right: 30px; } }

.cabinet-fields {
  padding: 0 0 50px; }
  .cabinet-fields .row {
    justify-content: center; }
  .cabinet-fields .btn {
    font-size: 14px;
    width: 100%;
    padding: 12px 15px; }

@media screen and (max-width: 767px) {
  .cabinet-fields form {
    margin: 0 0 30px; } }

@media screen and (max-width: 575px) {
  .cabinet-fields {
    padding: 0 0 20px; } }

.recom-item {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 18px);
  margin: 0 0 18px;
  align-items: stretch;
  align-content: stretch; }
  .recom-item:hover .recom-item__img::before {
    box-shadow: inset 0 0 0 7px #ff9898;
    transition: all ease 0.4s; }
  .recom-item__img {
    position: relative; }
    .recom-item__img img {
      max-width: 100%; }
  .recom-item__body {
    flex: 1;
    position: relative;
    background: #ffe8e8;
    padding: 15px 15px 30px;
    line-height: 1.2; }
  .recom-item__title {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 10px; }
  .recom-item__text {
    font-weight: 300; }
  .recom-item__link {
    position: absolute;
    bottom: 10px;
    right: 15px; }

.content-page {
  padding: 0 0 50px; }

@media screen and (max-width: 575px) {
  .recom-item {
    margin: 0 0 5px; }
    .recom-item__body {
      padding: 10px 10px 45px; }
    .recom-item__title {
      font-size: 14px; }
  .content-page {
    padding: 0 0 20px; } }

.back-title {
  display: block;
  cursor: auto; }

@media screen and (max-width: 767px) {
  .back-title {
    cursor: pointer;
    padding: 5px 0 0;
    margin: 0 0 12px; }
    .back-title span {
      position: relative; }
      .back-title span::before {
        position: absolute;
        top: 50%;
        margin-top: -13px;
        left: -35px;
        content: '';
        height: 23px;
        width: 23px;
        border-left: 2px solid #D8D8D8;
        border-bottom: 2px solid #D8D8D8;
        transform: rotate(45deg); } }

@media screen and (max-width: 575px) {
  input,
  option {
    font-size: 16px !important; }
  .back-title span {
    display: block;
    width: 100%;
    position: relative;
    padding: 0 0 0 40px; }
    .back-title span::before {
      left: 0; } }

.left-popup {
  z-index: 9999;
  max-width: 500px;
  width: 100%;
  position: absolute;
  top: 0;
  left: -500px;
  background: #ffe8e8;
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.15);
  padding: 20px 45px 35px;
  min-height: 100vh;
  transition: all ease 0.4s; }
  .left-popup.visible {
    left: 0; }

.popup {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none; }
  .popup__single .row > div > div {
    margin: 0 0 5px; }
  .popup__single img {
    width: 100%; }
  .popup__single .single__select {
    justify-content: flex-start; }
    .popup__single .single__select .js-select-style {
      margin-left: 10px; }
  .popup__inner {
    max-width: 562px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("../imgs/popup-bg.jpg") center no-repeat;
    background-size: cover;
    box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    padding: 20px 45px 35px; }
  .popup__close {
    position: absolute;
    top: 6px;
    right: 16px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: #ff9898 !important;
    text-align: center;
    transform: rotate(45deg);
    font-size: 55px;
    font-weight: 300;
    line-height: 1; }
    .popup__close:hover, .popup__close:focus {
      color: #9D9D9D; }
  .popup__title {
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 45px; }
  .popup__btn {
    text-align: center;
    margin: 25px 0 0; }
    .popup__btn .btn {
      font-size: 16px; }
  .popup__bottom {
    text-align: center;
    margin: 30px 0 0; }
    .popup__bottom p {
      color: #ff9898; }
      .popup__bottom p a {
        text-decoration: underline; }
    .popup__bottom a {
      display: inline-block;
      color: #ff9898; }
      .popup__bottom a#registration {
        border-bottom: 1px solid; }

.field-group {
  margin: 0 0 15px; }
  .field-group.required {
    position: relative; }
    .field-group.required::before {
      content: '*';
      position: absolute;
      top: 0;
      right: 5px;
      font-size: 24px;
      color: #ff9898; }
  .field-group__select {
    display: flex; }
    .field-group__select .jq-selectbox__select {
      height: 38px;
      line-height: 38px; }
    .field-group__select .jq-selectbox__trigger-arrow {
      top: 17px; }
  .field-group label {
    display: inline-block;
    margin: 0; }
    .field-group label sup {
      color: #ff9898;
      font-size: 20px; }
  .field-group textarea {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 16px;
    color: #bababa;
    background: #fff; }
  .field-group input {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 16px;
    color: #bababa;
    background: #fff; }
    .field-group input::placeholder {
      color: #bababa; }

@media screen and (max-width: 1349px) {
  .js-catalog + ul {
    display: none !important; }
  #main-left-popup {
    text-align: center;
    background: #ffe8e8; }
    #main-left-popup .with-list ul {
      padding: 5px 0; }
      #main-left-popup .with-list ul a {
        text-transform: initial; }
    #main-left-popup .left-popup__inner > div > a {
      color: #2d2d2d;
      display: block; }
    #main-left-popup .socials a {
      display: inline-block !important;
      padding: 0;
      margin: 10px 5px; }
    #main-left-popup .with-list ul {
      display: none;
      background: #ffe8e8; }
      #main-left-popup .with-list ul a {
        color: #2d2d2d; }
        #main-left-popup .with-list ul a::before {
          color: #2d2d2d; }
      #main-left-popup .with-list ul .with-list ul {
        display: none;
        background: #ff9898;
        border-bottom: 1px dashed #ffe8e8; }
        #main-left-popup .with-list ul .with-list ul a {
          color: #2d2d2d; }
          #main-left-popup .with-list ul .with-list ul a::before {
            color: #ff9898; }
    #main-left-popup .with-list > a {
      position: relative; }
      #main-left-popup .with-list > a.active::before {
        content: '-'; }
      #main-left-popup .with-list > a::before {
        font-size: 24px;
        content: '+';
        color: #ff9898;
        font-weight: 700;
        position: absolute;
        top: -5px;
        right: 20px; }
    #main-left-popup a {
      display: block;
      font-size: 16px;
      color: #2d2d2d;
      padding: 2px 10px;
      text-transform: uppercase; }
      #main-left-popup a.mail {
        text-transform: lowercase; }
      #main-left-popup a.popup__close {
        top: -4px;
        right: 5px;
        font-size: 55px;
        padding: 0; }
    #main-left-popup .left-popup__title {
      font-size: 18px;
      text-transform: uppercase;
      background: #ffe8e8;
      padding: 8px 10px;
      margin: 0 0 5px; } }

@media screen and (max-width: 321px) {
  #main-left-popup a {
    font-size: 14px; } }

@media screen and (max-width: 1349px) {
  .popup__inner {
    max-width: 320px;
    border-radius: 0; } }

@media screen and (max-width: 1349px) {
  #catalog-left-popup {
    text-align: center;
    background: #ff9898; }
    #catalog-left-popup .with-list ul {
      display: none;
      background: #ff9898; }
      #catalog-left-popup .with-list ul a {
        text-transform: capitalize;
        color: #fff; }
        #catalog-left-popup .with-list ul a::before {
          color: #fff; }
      #catalog-left-popup .with-list ul .with-list ul {
        display: none;
        background: #ffe8e8;
        border-bottom: 1px dashed #ffe8e8;
        position: relative; }
        #catalog-left-popup .with-list ul .with-list ul:before {
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
          right: 0;
          height: 1px;
          border-top: 1px dashed #ffe8e8; }
        #catalog-left-popup .with-list ul .with-list ul:after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          height: 1px;
          border-top: 1px dashed #ffe8e8; }
        #catalog-left-popup .with-list ul .with-list ul a {
          color: #2D2D2D;
          text-transform: lowercase; }
          #catalog-left-popup .with-list ul .with-list ul a::before {
            color: #fff; }
    #catalog-left-popup .with-list > a {
      display: block;
      position: relative; }
      #catalog-left-popup .with-list > a + ul a.active {
        background: #ffe8e8;
        color: #2d2d2d; }
        #catalog-left-popup .with-list > a + ul a.active::before {
          color: #2D2D2D; }
        #catalog-left-popup .with-list > a + ul a.active::after {
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
          right: 0;
          height: 1px;
          border-top: 1px dashed #ffe8e8; }
        #catalog-left-popup .with-list > a + ul a.active + ul {
          position: relative; }
          #catalog-left-popup .with-list > a + ul a.active + ul:after {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            right: 0;
            height: 1px;
            border-top: 1px dashed #ffe8e8; }
      #catalog-left-popup .with-list > a.active::before {
        content: '-'; }
      #catalog-left-popup .with-list > a::before {
        font-size: 24px;
        content: '+';
        color: #ffffff;
        font-weight: 700;
        position: absolute;
        top: -7px;
        right: 5px; }
    #catalog-left-popup a {
      font-size: 14px;
      color: #fff;
      padding: 2px 10px;
      text-transform: uppercase; }
      #catalog-left-popup a.popup__close {
        top: -4px;
        right: 5px;
        font-size: 55px;
        padding: 0; }
    #catalog-left-popup .left-popup__title {
      font-size: 16px;
      text-transform: uppercase;
      background: #ffe8e8;
      padding: 8px 10px;
      margin: 0 0 5px; }
  .left-popup {
    position: fixed;
    height: 100vh;
    padding: 0; }
    .left-popup__inner {
      height: 100vh;
      overflow-x: scroll; }
  .popup {
    height: 100vh; }
    .popup .field-group__select .js-select-style {
      width: 89px; }
    .popup .field-group__select input {
      max-width: calc(100% - 89px); }
    .popup .field-group input {
      padding: 8px 10px; }
    .popup .row {
      font-size: 14px; }
    .popup .h4 {
      font-size: 14px;
      line-height: 1.2; }
    .popup .single__price, .popup .single__selesct, .popup .single__btns {
      font-size: 16px; }
    .popup__title {
      margin: 0 0 20px; }
    .popup__inner {
      padding: 20px 15px 35px;
      overflow-x: scroll; }
  .field-group {
    margin: 0 0 7px; }
    .field-group input {
      padding: 9px 10px; } }

@media screen and (max-width: 767px) {
  .popup__inner {
    height: 100vh; }
  #one-click-popup .popup__inner {
    width: 100%;
    max-width: 100%; } }

#one-click-popup .popup__title {
  margin: 0 0 25px; }

#one-click-popup .field-group {
  margin: 0 0 5px; }

#one-click-popup .field-group label {
  font-size: 14px;
  margin: 0; }

#one-click-popup .popup__bottom {
  margin: 10px 0 0; }

#one-click-popup .popup__btn {
  margin: 15px 0 0; }

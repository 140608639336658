/* COMMON STYLE */

* {
    box-sizing: border-box; }

body {
    background: #fff;
    color: #2d2d2d;
    font-family: $body-font, sans-serif;
    font-size: $font-size-base;
    line-height: 1.6;
    font-weight: 400;
    overflow-x: hidden; }


.wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh; }

.main {
    flex: 1 0 auto; }

.footer {
    flex: 0 0 auto; }

a {
	color: #2d2d2d;
	text-decoration: none; }

a:hover,
a:active,
a:focus {
 	color: #2d2d2d;
 	outline: none; }

img {
	vertical-align: bottom; }

sup,
small {
    font-size: 75%; }

a,
img,
input,
textarea,
button {
    border: 0;
    background: rgba(0,0,0,0.0);
    transition: all 0.4s ease 0s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

input,
textarea,
select,
button {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

input::placeholder {
    opacity: 1; }


/* H1 - H6 */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $headers-font, sans-serif; }


/* FORMS */
label {
    display: inline-block;
    margin-bottom: 5px; }

input[type="tel"],
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    border: none;
    display: inline-block;
    font-family: $body-font;
    font-size: $font-size-base;
    padding: 1em 1.5em;
    width: 100%;
    background: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
    border-color: $primary-color;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

textarea {
    resize: vertical; }

textarea::placeholder {
    opacity: 1; }


input[type="text"]:focus::placeholder,
input[type="email"]:focus::placeholder,
input[type="password"]:focus::placeholder,
textarea:focus::placeholder {
    opacity: 0; }

/* SELECT */
select {
    /*for WebKit*/
    -webkit-appearance: none;
    /* for FF */
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '...';
    /* for IE */
    -ms-appearance: none;
    appearance: none !important; }


select::-ms-expand {
    display: none; }

/* BUTTONS */
input[type="button"],
input[type="submit"],
input[type="reset"],
button {
    cursor: pointer;
    display: inline-block;
    font-size: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover {}

.btn {
    display: inline-block;
    padding: 8px 15px;
    line-height: 1;
    transition: all ease 0.4s;
    background-color: rgba(0,0,0,0.0);
    color: #000;
    border: 1px solid;
    border-radius: 4px;
    font-size: 22px;
    min-width: 230px;
    max-width: 100%;
    text-align: center; }

@media screen and (max-width: $small) {
    .btn {
        min-width: 180px;
        width: 100%; } }

.btn-primary {
    background: $primary-color;
    color: #ffffff;
    border-color: $primary-color;
    &:hover,
    &:focus {
        box-shadow: inset 2px 2px 0 $primary-color, inset -2px -2px 0 $primary-color;
        background: rgba(0,0,0,0.0);
        border-color: $add-color;
        color: $primary-color; } }
.btn-add {
    background: $add-color;
    color: #2d2d2d;
    border-color: $add-color;
    &:hover,
    &:focus {
        box-shadow: inset 2px 2px 0 $add-color, inset -2px -2px 0 $add-color;
        background: rgba(0,0,0,0.0);
        border-color: $add-color;
        color: #2d2d2d; } }




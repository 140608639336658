//Цвета макета
$primary-color: #ff9898;
$add-color: #ffe8e8;

// Размер шрифта
$fs-50: 3.125em;
$fs-48: 3.000em;
$fs-40: 2.500em;
$fs-36: 2.250em;
$fs-32: 2.000em;
$fs-30: 1.875em;
$fs-26: 1.625em;
$fs-24: 1.500em;
$fs-22: 1.375em;
$fs-21: 1.313em;
$fs-20: 1.250em;
$fs-19: 1.188em;
$fs-18: 1.125em;
$font-size-base: 16px;
$fs-15: 0.938em;
$fs-14: 0.875em;
$fs-13: 0.813em;
$fs-12: 0.750em;
$fs-11: 0.688em;

//GRID
// Media queries/container
$desktopM: 1350px; //xl
$tabletM: 992px; //lg
$mobileM: 768px; //md
$smallM: 576px; //sm


$desktop: 1349px;
$tablet: 991px;
$mobile: 767px;
$small: 575px;


$desktopC: 1300px;
$tabletC: 970px;
$mobileC: 750px;
$smallC: 100%;

// Шрифты
$add-font: 'Lato';
$body-font: 'Lato';
$headers-font: 'Lato';
